import "./TerminosCondiciones.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function TerminosCondiciones() {

    const { code } = useParams();
    const [ruta, setRuta] = useState({});

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/ruta/${code}`, { method: "GET" }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setRuta(data.data);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        }).catch(error => {
            console.log(JSON.stringify(error));
        });
  
    }, [code])

    return (
        <div className="TerminosCondiciones">
          <div className="div-terminos-condiciones-pdf-body">
            <Container>
              <Row className="justify-content-center">
                <Col lg={12}>
                    {
                        ruta.terminosCondiciones &&
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <div className="div-terminos-condiciones-pdf-viewer">
                                <Viewer fileUrl={ruta.terminosCondiciones} />
                            </div>
                        </Worker>
                    }
                </Col>
              </Row>
            </Container>
          </div>
          <VolverInicio Path={`/rutas/${code}`} Texto={"volver-inicio.servicios"} />
        </div>
      );
}

export default TerminosCondiciones;
