import "./AdminTarjetas.css";
import { useEffect, useState, useRef } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
import Swal from "sweetalert2";
import Loading from "../../components/loading/Loading";
import AdminNavbar from "../../components/navbar/AdminNavbar";
import ButtonClose from "./src/close-circle.svg";
import Celular from "./src/Celular.svg";
import Direccion from "./src/Direccion.svg";
import Email from "./src/Email.svg";
import Linkedin from "./src/Linkedin.svg";
import Telefono from "./src/Telefono.svg";
import Whatsapp from "./src/Whatsapp.svg";
import QRCode from "react-qr-code";
import { toJpeg, toPng, toSvg } from "html-to-image";

import PreviewEmail from "../../../tarjeta/src/Email.svg";
import PreviewLinkedin from "../../../tarjeta/src/Linkedin.svg";
import PreviewWhatsapp from "../../../tarjeta/src/Whatsapp.svg";
import PreviewCorreo from "../../../tarjeta/src/correo.svg";
import PreviewTelefono from "../../../tarjeta/src/telefono.svg";
import PreviewCelular from "../../../tarjeta/src/celular.svg";
import PreviewDireccion from "../../../tarjeta/src/direccion.svg";
import PreviewAgregarContacto from "../../../tarjeta/src/agregar_contacto.svg";
import PreviewLogo from "../../../tarjeta/src/tabsa_logo.png";

function AdminKeys() {
    const [usuario, setUsuario] = useState(sessionStorage.getItem("admin-user"));

    if (!usuario || usuario === "") {
        window.location.href = "/admincms/login";
    }

    const divEndRef = useRef(null);
    const [showLoading, setShowLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [tarjetas, setTarjetas] = useState([]);
    const [data, setData] = useState({
        showTable: true,
        creating: false,
    });

    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "Documento QR"
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASEURL}/tarjetas/tarjetas`, { method: "GET" }).then((response) => {
            if (response.ok) {
                response.json().then(dataResponse => {
                    setTarjetas(dataResponse.data);
                    setShowLoading(false);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        })
    }, []);

    return (
        <>
            <AdminNavbar />
            <Loading show={showLoading} />
            <Container>
                <Row className="justify-content-center content-admin-tarjetas">
                    <Col lg={11}>
                        <Row className="justify-content-center">
                            <Col lg={10}>
                                <p className="p-titulo-admin">Tarjetas</p>
                            </Col>
                            <Col lg={2}>
                            </Col>
                        </Row>
                        {
                            data.showTable &&
                            <>
                            <Row>
                                <br />
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={5}>
                                    <Form.Label className="form-label-tarjetas">Buscador</Form.Label>
                                    <Form.Control 
                                        className={`form-field-tarjetas no-margin-tarjetas`}
                                        type="text" 
                                        id="txtSearch"
                                        placeholder="Búsqueda por texto contenido en nombre o correo."
                                        value={search} 
                                        onChange={(evt) => setSearch(evt.target.value)}
                                    />
                                </Col>
                                <Col lg={4}>
                                </Col>
                                <Col lg={3}>
                                    <Button className="btn-admin-new-tarjetas" onClick={() => {
                                        setData({
                                            showTable: false,
                                            creating: true,
                                            email: "", 
                                            nombre: "", 
                                            cargo: "", 
                                            imagen: "", 
                                            whatsapp: "", 
                                            linkedin: "", 
                                            telefono_fijo: "", 
                                            telefono_movil: "", 
                                            direccion: "", 
                                            titulo_descripcion: "", 
                                            descripcion: ""
                                        });
                                    }}>Crear Tarjeta</Button>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <table className="table-admin-tarjetas">
                                        <thead>
                                            <tr>
                                                <td width={"5%"}>#</td>
                                                <td width={"35%"}>Nombre Completo</td>
                                                <td width={"30%"}>Correo</td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            tarjetas
                                            .filter(x => (
                                                (
                                                    search.trim().length > 0 
                                                    && (
                                                        x.nombre.toUpperCase().includes(search.trim().toUpperCase()) 
                                                        || x.email.toUpperCase().includes(search.trim().toUpperCase())
                                                    )
                                                ) 
                                                || (
                                                    search.trim().length === 0
                                                )
                                            ))
                                            .map((r, i) => (
                                                <tr key={`tkeys-${i}`} className={`${i > 0 ? "tr-row": ""}`}>
                                                    <td className="bold">
                                                        {i + 1}
                                                    </td>
                                                    <td>
                                                        {r.nombre}
                                                    </td>
                                                    <td>
                                                        {r.email}
                                                    </td>
                                                    <td>
                                                        <Button className="btn-admin-ver-qr-tarjetas-table" onClick={() => {

                                                            setShowLoading(true);

                                                            fetch(`${process.env.REACT_APP_API_BASEURL}/tarjetas/tarjeta/${r.email}`, { 
                                                                method: "GET",
                                                                headers: {
                                                                    "Content-Type": "application/json"
                                                                }
                                                            }).then((response) => {
                                                                if (response.ok) {
                                                                    response.json().then(data => {

                                                                        setData({
                                                                            showTable: false,
                                                                            creating: false,
                                                                            email: data.data.email, 
                                                                            nombre: data.data.nombre, 
                                                                            cargo: data.data.cargo, 
                                                                            imagen: data.data.imagen, 
                                                                            whatsapp: data.data.whatsapp, 
                                                                            linkedin: data.data.linkedin, 
                                                                            telefono_fijo: data.data.telefono_fijo, 
                                                                            telefono_movil: data.data.telefono_movil, 
                                                                            direccion: data.data.direccion, 
                                                                            titulo_descripcion: data.data.titulo_descripcion, 
                                                                            descripcion: data.data.descripcion
                                                                        });

                                                                        setShowLoading(false);
                                                                        setTimeout(() => {
                                                                            divEndRef.current.scrollIntoView({ behavior: "smooth" });
                                                                        }, 1000);
                                                                    })
                                                                }
                                                                else {
                                                                    console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                }
                                                            });
                                                        }}>Ver QR</Button>
                                                        <Button className="btn-admin-editar-tarjetas-table" onClick={() => {
    
                                                            setShowLoading(true);

                                                            fetch(`${process.env.REACT_APP_API_BASEURL}/tarjetas/tarjeta/${r.email}`, { 
                                                                method: "GET",
                                                                headers: {
                                                                    "Content-Type": "application/json"
                                                                }
                                                            }).then((response) => {
                                                                if (response.ok) {
                                                                    response.json().then(data => {

                                                                        setData({
                                                                            showTable: false,
                                                                            creating: false,
                                                                            email: data.data.email, 
                                                                            nombre: data.data.nombre, 
                                                                            cargo: data.data.cargo, 
                                                                            imagen: data.data.imagen, 
                                                                            whatsapp: data.data.whatsapp, 
                                                                            linkedin: data.data.linkedin, 
                                                                            telefono_fijo: data.data.telefono_fijo, 
                                                                            telefono_movil: data.data.telefono_movil, 
                                                                            direccion: data.data.direccion, 
                                                                            titulo_descripcion: data.data.titulo_descripcion, 
                                                                            descripcion: data.data.descripcion
                                                                        });

                                                                        setShowLoading(false);
                                                                    })
                                                                }
                                                                else {
                                                                    console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                }
                                                            });
                                                        }}>Editar</Button>
                                                        <Button className="btn-admin-eliminar-tarjetas-table" onClick={() => {
                                                            Swal.fire({
                                                                icon: "info",
                                                                title: "¿Estás seguro de eliminar la tarjeta?",
                                                                showDenyButton: true,
                                                                confirmButtonText: "Eliminar",
                                                                denyButtonText: "Cancelar"
                                                            }).then(alResponse => {
                                                                if (alResponse.isConfirmed) {
                                                                    setShowLoading(true);
    
                                                                    fetch(`${process.env.REACT_APP_API_BASEURL}/tarjetas/tarjeta`, { 
                                                                        method: "DELETE",
                                                                        headers: {
                                                                            "Content-Type": "application/json"
                                                                        },
                                                                        body: JSON.stringify({ email: r.email, ultimoUsuario: usuario })
                                                                    }).then((response) => {
                                                                        if (response.ok) {
                                                                            response.json().then(data => {
    
                                                                                let newTarjetas = tarjetas;
                                                                                newTarjetas.splice(i, 1);
                                                                                setTarjetas(newTarjetas);
    
                                                                                setShowLoading(false);
                                                                            })
                                                                        }
                                                                        else {
                                                                            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                        }
                                                                    });
                                                                }
                                                            })
                                                        }}>Eliminar</Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            </>
                        }
                        {
                            !data.showTable &&
                            <>
                            <Row>
                                <Col lg={11}></Col>
                                <Col lg={1}>
                                    <img src={ButtonClose} className="btn-close-tarjetas" alt="close" onClick={() => {
                                        setData({
                                            showTable: true,
                                            creating: true,
                                            email: "", 
                                            nombre: "", 
                                            cargo: "", 
                                            imagen: "", 
                                            whatsapp: "", 
                                            linkedin: "", 
                                            telefono_fijo: "", 
                                            telefono_movil: "", 
                                            direccion: "", 
                                            titulo_descripcion: "", 
                                            descripcion: ""
                                        });
                                    }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={7}>
                                    <Row>
                                        <Col lg={10}>
                                            <Row>
                                                <Col>
                                                    <p className="subp-titulo-admin">Datos</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <Form.Label className="form-label-tarjetas">Nombre Completo</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-tarjetas`}
                                                        type="text" 
                                                        id="txtNombre"
                                                        placeholder="Nombre Completo"
                                                        value={data.nombre} 
                                                        onChange={(evt) => setData({ ...data, nombre: evt.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <Form.Label className="form-label-tarjetas">Cargo</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-tarjetas`}
                                                        type="text" 
                                                        id="txtCargo"
                                                        placeholder="Cargo"
                                                        value={data.cargo} 
                                                        onChange={(evt) => setData({ ...data, cargo: evt.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <Form.Label className="form-label-tarjetas">Foto de perfil</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-file-tarjetas`} 
                                                        type="file" 
                                                        id="txtFile"
                                                        placeholder="."
                                                        accept="image/jpeg"
                                                        onChange={(evt) => {
                                                            if (evt.target.files.length > 0) {

                                                                setShowLoading(true);

                                                                let formData = new FormData();

                                                                formData.append("pdf", evt.target.files[0]);
                                                                
                                                                fetch('https://recursos.tabsa.cl/api/file/save', {
                                                                    method: "POST",
                                                                    body: formData
                                                                })
                                                                .then(response => {
                                                                    if (response.ok) {
                                                                        response.json().then(file => {
                                                                            setData({ ...data, imagen: file.url })
                                                                            setShowLoading(false);
                                                                        })
                                                                        .catch(error => {
                                                                            console.log(error)
                                                                        })
                                                                    }
                                                                    else {
                                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                        setShowLoading(false);
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                })

                                                            }
                                                        }}
                                                    />
                                                    <br />
                                                    <p className="label-admin-recomendacion">* Resolución recomendada: 720x640</p> 
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={2}>
                                                    <img src={Whatsapp} className="icon-tarjetas" alt="whatsapp"/>
                                                </Col>
                                                <Col lg={10}>
                                                    <Form.Label className="form-label-tarjetas">Whatsapp</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-tarjetas`}
                                                        type="text" 
                                                        id="txtWhatsapp"
                                                        placeholder="+56912345678"
                                                        value={data.whatsapp} 
                                                        onChange={(evt) => setData({ ...data, whatsapp: evt.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={2}>
                                                    <img src={Linkedin} className="icon-tarjetas" alt="linkedin"/>
                                                </Col>
                                                <Col lg={10}>
                                                    <Form.Label className="form-label-tarjetas">Linkedin</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-tarjetas`}
                                                        type="text" 
                                                        id="txtLinkedin"
                                                        placeholder="https://cl.linkedin.com/in/..."
                                                        value={data.linkedin} 
                                                        onChange={(evt) => setData({ ...data, linkedin: evt.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={2}>
                                                    <img src={Email} className="icon-tarjetas" alt="correo"/>
                                                </Col>
                                                <Col lg={10}>
                                                    <Form.Label className="form-label-tarjetas">Correo</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-tarjetas`}
                                                        type="text" 
                                                        id="txtEmail"
                                                        placeholder="...@tabsa.cl"
                                                        value={data.email} 
                                                        onChange={(evt) => setData({ ...data, email: evt.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={2}>
                                                    <img src={Telefono} className="icon-tarjetas" alt="telefono"/>
                                                </Col>
                                                <Col lg={10}>
                                                    <Form.Label className="form-label-tarjetas">Telefono Fijo</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-tarjetas`}
                                                        type="text" 
                                                        id="txtTelefonoFijo"
                                                        placeholder="+(56) (61) 272 8100"
                                                        value={data.telefono_fijo} 
                                                        onChange={(evt) => setData({ ...data, telefono_fijo: evt.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={2}>
                                                    <img src={Celular} className="icon-tarjetas" alt="movil"/>
                                                </Col>
                                                <Col lg={10}>
                                                    <Form.Label className="form-label-tarjetas">Telefono Móvil</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-tarjetas`}
                                                        type="text" 
                                                        id="txtTelefonoMovil"
                                                        placeholder="+(56) (9) 1234 5678"
                                                        value={data.telefono_movil} 
                                                        onChange={(evt) => setData({ ...data, telefono_movil: evt.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={2}>
                                                    <img src={Direccion} className="icon-tarjetas" alt="direccion"/>
                                                </Col>
                                                <Col lg={10}>
                                                    <Form.Label className="form-label-tarjetas">Dirección</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-tarjetas`}
                                                        type="text" 
                                                        id="txtDireccion"
                                                        placeholder="Juan Williams 06450, Pta. Arenas, Magallanes, Chile"
                                                        value={data.direccion} 
                                                        onChange={(evt) => setData({ ...data, direccion: evt.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <Form.Label className="form-label-tarjetas">Título de la Descripción</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-tarjetas`}
                                                        type="text" 
                                                        id="txtTituloDescripcion"
                                                        placeholder="Acerca de mí"
                                                        value={data.titulo_descripcion} 
                                                        onChange={(evt) => setData({ ...data, titulo_descripcion: evt.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <Form.Label className="form-label-tarjetas">Descripción</Form.Label>
                                                    <Form.Control 
                                                        className={`form-area-tarjetas`}
                                                        type="text"
                                                        as="textarea" 
                                                        id="txtDescripcion"
                                                        placeholder="Descripción"
                                                        value={data.descripcion} 
                                                        onChange={(evt) => setData({ ...data, descripcion: evt.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={2}>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={2}></Col>
                                        <Col lg={8}>
                                            <Button className="btn-admin-editar-tarjetas" onClick={() => {
    
                                                setShowLoading(true);

                                                fetch(`${process.env.REACT_APP_API_BASEURL}/tarjetas/tarjeta`, { 
                                                    method: "POST",
                                                    headers: {
                                                        "Content-Type": "application/json"
                                                    },
                                                    body: JSON.stringify({ ...data, ultimoUsuario: usuario })
                                                }).then((response) => {
                                                    if (response.ok) {
                                                        response.json().then(dataResponse => {

                                                            Swal.fire({
                                                                icon: "info",
                                                                title: "Guardado correctamente",
                                                                confirmButtonText: "Ok"
                                                            }).then(swalResponse => {



                                                                setData({
                                                                    showTable: true,
                                                                    creating: true,
                                                                    email: "", 
                                                                    nombre: "", 
                                                                    cargo: "", 
                                                                    imagen: "", 
                                                                    whatsapp: "", 
                                                                    linkedin: "", 
                                                                    telefono_fijo: "", 
                                                                    telefono_movil: "", 
                                                                    direccion: "", 
                                                                    titulo_descripcion: "", 
                                                                    descripcion: ""
                                                                });
                                                                setShowLoading(false);
                                                            })
                                                        })
                                                    }
                                                    else {
                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                    }
                                                });
                                            }}>Guardar</Button>
                                        </Col>
                                        <Col lg={2}></Col>
                                    </Row>
                                </Col>
                                <Col lg={5}>
                                    <Row>
                                        <Col>
                                            <p className="subp-titulo-admin">Previsualización</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={10}>
                                            <div className="div-tarjeta add-border">
                                                {
                                                    data.imagen &&
                                                    <div className="div-img-tarjeta">
                                                        <img src={data.imagen} alt="" className="img-tarjeta" />
                                                        <div className="div-img-titulo">
                                                            <div className="div-titulo">
                                                                <p className="p-nombre">{data.nombre}</p>
                                                                <p className="p-cargo">{data.cargo}</p>
                                                            </div>
                                                        </div>    
                                                    </div>
                                                }
                                                <div className="div-info">
                                                    <div className="div-icon-buttons">
                                                        {
                                                            data.whatsapp &&
                                                            <a>
                                                                <img src={PreviewWhatsapp} alt="whatsapp" className="icon-button" />
                                                            </a>
                                                        }
                                                        {
                                                            data.linkedin &&
                                                            <a>
                                                                <img src={PreviewLinkedin} alt="linkedin" className="icon-button" />
                                                            </a>
                                                        }
                                                        {
                                                            data.email &&
                                                            <a>
                                                                <img src={PreviewEmail} alt="email" className="icon-button" />
                                                            </a>
                                                        }
                                                    </div>
                                                    <div className="div-contacto">
                                                        <p className="p-contacto-titulo">Contáctame</p>
                                                        <br />
                                                        <div className="div-row-contacto">
                                                            <a className="link-contacto">
                                                                <img src={PreviewCorreo} alt="email" className="icon-contacto" />
                                                                {data.email}
                                                            </a>
                                                        </div>
                                                        {
                                                            data.telefono_fijo &&
                                                            <div className="div-row-contacto">
                                                                <a className="p-contacto">
                                                                    <img src={PreviewTelefono} alt="telefono_fijo" className="icon-contacto" />
                                                                    {data.telefono_fijo}
                                                                </a>
                                                            </div>
                                                        }
                                                        {
                                                            data.telefono_movil &&
                                                            <div className="div-row-contacto">
                                                                <a className="p-contacto">
                                                                    <img src={PreviewCelular} alt="telefono_movil" className="icon-contacto" />
                                                                    {data.telefono_movil}
                                                                </a>
                                                            </div>
                                                        }
                                                        {
                                                            data.direccion &&
                                                            <div className="div-row-contacto">
                                                                <a className="p-contacto">
                                                                    <img src={PreviewDireccion} alt="direccion" className="icon-contacto" />
                                                                    {data.direccion}
                                                                </a>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="div-guardar-contacto">
                                                        <Button className="btn-guardar-contacto">
                                                            <img src={PreviewAgregarContacto} alt="agregar contacto" className="icon-agregar-contacto" />
                                                            Guardar contacto
                                                        </Button>
                                                    </div>
                                                    <div className="div-descripcion">
                                                        {
                                                            data.titulo_descripcion &&
                                                            <p className="p-titulo-descripcion">{data.titulo_descripcion}</p>
                                                        }
                                                        {
                                                            data.descripcion &&
                                                            <p className="p-descripcion">{data.descripcion}</p>
                                                        }
                                                    </div>
                                                    <div className="div-footer">
                                                        <img src={PreviewLogo} className="img-logo" alt="tabsa" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            </>
                        }
                        {
                            (!data.showTable && !data.creating) &&
                            <>
                            <Row>
                                <Col>
                                    <hr />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={4}>
                                    <Row>
                                        <Col lg={12}>
                                            <p className="subp-titulo-admin" style={{textAlign: "center"}}>Código QR</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col ref={printRef} style={{backgroundColor:"#FFFFFF"}} lg={12}>
                                            <p className="qr-code-tarjetas-label">{data.nombre}</p>
                                            <div className="qr-code-tarjetas">
                                                <QRCode
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={`${process.env.REACT_APP_QR_BASE_URL}/tarjeta/${data.email}`}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </div>
                                            <p className="qr-code-tarjetas-label">{data.email}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <br/>
                                            <Button className="btn-admin-editar-tarjetas" onClick={() => {
                                                toPng(printRef.current, { cacheBust: false })
                                                .then((dataUrl) => {
                                                  const link = document.createElement("a");
                                                  link.download = `QR ${data.email}.png`;
                                                  link.href = dataUrl;
                                                  link.click();
                                                })
                                                .catch((err) => {
                                                  console.log(err);
                                                });
                                            }}>Descargar PNG</Button>
                                            <br/>
                                            <Button className="btn-admin-editar-tarjetas" onClick={() => {
                                                toJpeg(printRef.current, { cacheBust: false })
                                                .then((dataUrl) => {
                                                  const link = document.createElement("a");
                                                  link.download = `QR ${data.email}.jpg`;
                                                  link.href = dataUrl;
                                                  link.click();
                                                })
                                                .catch((err) => {
                                                  console.log(err);
                                                });
                                            }}>Descargar JPG</Button>
                                            <br/>
                                            <Button className="btn-admin-editar-tarjetas" onClick={() => {
                                                toSvg(printRef.current, { cacheBust: false })
                                                .then((dataUrl) => {
                                                  const link = document.createElement("a");
                                                  link.download = `QR ${data.email}.svg`;
                                                  link.href = dataUrl;
                                                  link.click();
                                                })
                                                .catch((err) => {
                                                  console.log(err);
                                                });
                                            }}>Descargar SVG</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Button className="btn-admin-new-tarjetas" onClick={handlePrint}>Imprimir QR</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Button className="btn-admin-link-tarjetas" onClick={() => { 
                                                window.open(`${process.env.REACT_APP_QR_BASE_URL}/tarjeta/${data.email}`, "_blank");
                                            }}>Previsualizar la tarjeta</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            </>
                        }
                    </Col>
                    <div ref={divEndRef} />
                </Row>
            </Container>
        </>
    )
}

export default AdminKeys;