import "./Footer.css";
import { Row, Col } from "react-bootstrap";
import Logo from "./src/logo-tabsa-color.png";
import IconoCorreo from "./src/icono-correo.svg";
import IconoCelular from "./src/icono-celular.svg";
import IconoDireccion from "./src/icono-dirección.svg";
import IconoFacebook from "./src/icono-facebook.svg";
import IconoX from "./src/icono-x.svg";
import IconoYoutube from "./src/icono-youtube.svg";
import IconoInstagram from "./src/icono-instagram.svg";

import { useTranslation } from "react-i18next";

function Footer({ clickOut }) {
    const [t, i18n] = useTranslation("global");

    return (
        <>
            <Row className="footer-back justify-content-center" onClick={() => { clickOut(false, false, false, false, false) }}>
                <Col className="justify-content-center" lg={{ span: 4, order: 1 }} sm={{ span: 12, order: 3 }} xs={{ span: 12, order: 3 }}>
                    <img className="footer-logo" alt="tabsa" src={Logo} />
                </Col>
                <Col style={{ textAlign: "left" }} lg={{ span: 4, offset: 0 }} sm={{ span: 4, order: 1, offset: 2 }} xs={{ span: 6, order: 1 }}>
                    <Row>
                        <Col lg={6} sm={12} xs={12}>
                            <Row>
                                <Col>
                                    <a className="footer-buttons" href="/nosotros">{t("footer.nosotros")}</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a className="footer-buttons" href="/historia">{t("footer.historia")}</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a className="footer-buttons" href="/ejecutivos">{t("footer.ejecutivos")}</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a className="footer-buttons" href="/preguntas">{t("footer.preguntas")}</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a className="footer-buttons" href="https://app.canalintegral.cl/Denunciante/Denuncia/820749">Portal de Denuncias</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col>
                                    <a className="footer-buttons" href="/trabaja-con-nosotros">{t("footer.trabaja")}</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a className="footer-buttons" href="/oficinas-terminales">{t("footer.oficinas")}</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a className="footer-buttons" href="/contactanos">{t("footer.contactanos")}</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a className="footer-buttons" href="https://intranet.tabsa.cl">Acceso Intranet</a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col style={{ textAlign: "left" }} lg={4} sm={{ span: 6, order: 2 }} xs={{ span: 6, order: 2 }}>
                    <Row>
                        <Col lg={6} sm={12} xs={12}>
                            <Row style={{ marginBottom: "20px" }}>
                                <Col lg={2} sm={3} xs={3}>
                                    <img className="footer-button-image" alt="correo" src={IconoCorreo} />
                                </Col>
                                <Col lg={10} sm={9} xs={9}>
                                    <a className="footer-buttons" href="mailto:reservas@tabsa.cl">reservas@tabsa.cl</a>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "20px" }}>
                                <Col lg={2} sm={3} xs={3}>
                                    <img className="footer-button-image" alt="celular" src={IconoCelular} />
                                </Col>
                                <Col lg={10} sm={9} xs={9}>
                                    <a className="footer-buttons" href="#">+(56) (61) 272 8100</a>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "20px" }}>
                                <Col lg={2} sm={3} xs={3}>
                                    <img className="footer-button-image" alt="dirección" src={IconoDireccion} />
                                </Col>
                                <Col lg={10} sm={9} xs={9}>
                                    <a className="footer-buttons" target="_blank" href="https://www.google.com/maps/place/Juan+Williams+06450,+Punta+Arenas,+Magallanes+y+la+Ant%C3%A1rtica+Chilena/@-53.1141832,-70.8784896,17.5z/data=!4m6!3m5!1s0xbdb2654f2e3f572b:0x5b0dee7a89d7857e!8m2!3d-53.1141352!4d-70.8785371!16s%2Fg%2F11t0gdysgv?entry=ttu">Juan Williams 06450, Pta. Arenas, Magallanes, Chile</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <Row style={{ marginBottom: "20px" }}>
                                <Col lg={2} sm={3} xs={3}>
                                    <img className="footer-button-image" alt="facebook" src={IconoFacebook} />
                                </Col>
                                <Col lg={10} sm={9} xs={9}>
                                    <a className="footer-buttons" target="_blank" href="https://web.facebook.com/TABSA/?locale=es_LA&_rdc=1&_rdr">@TABSA</a>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "20px" }}>
                                <Col lg={2} sm={3} xs={3}>
                                    <img className="footer-button-image" alt="x" src={IconoX} />
                                </Col>
                                <Col lg={10} sm={9} xs={9}>
                                    <a className="footer-buttons" target="_blank" href="https://twitter.com/_tabsa?lang=es">@_tabsa</a>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "20px" }}>
                                <Col lg={2} sm={3} xs={3}>
                                    <img className="footer-button-image" alt="instagram" src={IconoInstagram} />
                                </Col>
                                <Col lg={10} sm={9} xs={9}>
                                    <a className="footer-buttons" target="_blank" href="https://instagram.com/_tabsa">@_tabsa</a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="footer-copyrights justify-content-center" onClick={() => { clickOut(false, false, false, false, false) }}>
                <p style={{marginTop:"7px"}}>{t("footer.copyright")}</p>
            </Row>
        </>
    )
}

export default Footer;