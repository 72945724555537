import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import App from './home/App';
import Cruces from "./cruces/Cruces";
import Rutas from "./rutas/Rutas";
import Ferry from "./rutas/Ferry/Ferry";
import Tarifas from "./rutas/Tarifas/Tarifas";
import Atencion from "./rutas/Atencion/Atencion";
import InformacionInteres from "./rutas/InformacionInteres/InformacionInteres";
import ManualOperaciones from './rutas/ManualOperaciones/ManualOperaciones';
import TerminosCondiciones from "./rutas/TerminosCondiciones/TerminosCondiciones";
import Services from "./services/Services";
import Tarjeta from "./tarjeta/Tarjeta";
import Itinerarios from "./itinerarios/Itinerarios";
import Ejecutivos from "./nosotros/Ejecutivos/Ejecutivos";
import Historia from "./nosotros/Historia/Historia";
import Nosotros from "./nosotros/QuienesSomos/QuienesSomos";
import PreguntasFrecuentes from "./nosotros/PreguntasFrecuentes/PreguntasFrecuentes";
import Contactanos from "./nosotros/Contactanos/Contactanos";
import Contactanos2 from "./nosotros/Contactanos/Contactanos2";
import TrabajaNosotros from "./nosotros/TrabajaNosotros/TrabajaNosotros";
import OficinasTerminales from "./nosotros/OficinasTerminales/OficinasTerminales";
import SolicitarUsuario from "./nosotros/SolicitarUsuario/SolicitarUsuario";

//{ /* RUTAS FIJAS DE ITINERARIOS */ }
import PAPWItinerario from "./services/PArenasPWilliams/PAPWItinerario/PAPWItinerario";
import PAPOItinerario from "./services/PArenasPorvenir/PAPOItinerario/PAPOItinerario";
import PYPNItinerario from "./services/PYungayPNatales/PYPNItinerario/PYPNItinerario";
import CRVIRItinerario from "./services/CRioVerdeIslaRiesco/CRVIRItinerario/CRVIRItinerario";
import PRANItinerario from "./services/PrimeraAngostura/PRANItinerario/PRANItinerario";
import PAIMItinerario from "./services/PArenasIMagdalena/PAIMItinerario/PAIMItinerario";

//{ /* RUTAS FIJAS DE ITINERARIOS */ }

import AdminLogin from "./admin/pages/login/AdminLogin";
import AdminCMS from "./admin/AdminCMS";
import AdminBanners from "./admin/pages/banners/AdminBanners";
import AdminCarrousel from "./admin/pages/carrousel/AdminCarrousel";
import AdminModals from "./admin/pages/modals/AdminModals";
import AdminServicios from "./admin/pages/servicios/AdminServicios";
import AdminRutas from "./admin/pages/rutas/AdminRutas";
import AdminKeys from "./admin/pages/keys/AdminKeys";
import AdminTarjetas from "./admin/pages/tarjetas/AdminTarjetas";

import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";

/* Google Analytics */
import ReactGA from 'react-ga4';
ReactGA.initialize('G-X3DZFNCB4R');

if (!localStorage.getItem('i18nextLng')) {
  localStorage.setItem('i18nextLng', 'es');
}

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('i18nextLng'),
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<App />} />
          <Route path="/portal" element={<App />} />
          <Route path="/inicio" element={<App />} />
          <Route path="/estado-cruces" element={<Cruces />} />


          <Route path="/rutas/:code" element={<Rutas />} />
          <Route path="/rutas/:code/tarifas" element={<Tarifas />} />
          <Route path="/rutas/:code/info-interes" element={<InformacionInteres />} />
          <Route path="/rutas/:code/manual-operaciones" element={<ManualOperaciones />} />
          <Route path="/rutas/:code/terminos-y-condiciones" element={<TerminosCondiciones />} />
          <Route path="/rutas/:code/ferry" element={<Ferry />} />
          <Route path="/rutas/:code/atencion" element={<Atencion />} />

          { /* RUTAS FIJAS DE ITINERARIOS */ }
          <Route path="/ruta/parenas-pwilliams/itinerarios" element={<PAPWItinerario />} />
          <Route path="/ruta/parenas-porvenir/itinerarios" element={<PAPOItinerario />} />
          <Route path="/ruta/pyungay-pnatales/itinerarios" element={<PYPNItinerario />} />
          <Route path="/ruta/crioverde-islariesco/itinerarios" element={<CRVIRItinerario />} />
          <Route path="/ruta/primera-angostura/itinerarios" element={<PRANItinerario />} />
          <Route path="/ruta/parenas-imagdalena/itinerarios" element={<PAIMItinerario />} />

          { /* RUTAS FIJAS DE ITINERARIOS */ }

          <Route path="/servicios/:code" element={<Services />} />
          <Route path="/tarjeta/:email" element={<Tarjeta />} />

          <Route path="/itinerarios" element={<Itinerarios />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/historia" element={<Historia />} />
          <Route path="/ejecutivos" element={<Ejecutivos />} />
          <Route path="/preguntas" element={<PreguntasFrecuentes />} />
          <Route path="/contactanos" element={<Contactanos />} />
          <Route path="/contactanos2" element={<Contactanos2 />} />

          <Route path="/trabaja-con-nosotros" element={<TrabajaNosotros />} />
          <Route path="/oficinas-terminales" element={<OficinasTerminales />} />
          <Route path="/solicitar-usuario" element={<SolicitarUsuario />} />

          <Route path="/admincms/login" element={<AdminLogin />} />
          <Route path="/admincms" element={<AdminCMS />} />
          <Route path="/admincms/banners" element={<AdminBanners />} />
          <Route path="/admincms/carrousel" element={<AdminCarrousel />} />
          <Route path="/admincms/modals" element={<AdminModals />} />
          <Route path="/admincms/servicios" element={<AdminServicios />} />
          <Route path="/admincms/rutas" element={<AdminRutas />} />
          <Route path="/admincms/keys" element={<AdminKeys />} />
          <Route path="/admincms/tarjetas" element={<AdminTarjetas />} />


        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
