import "./AdminCarrousel.css";
import { useEffect, useState } from "react";
import { Container, Col, Row, Carousel, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import NextIcon from "./src/next-arrow.svg";
import PrevIcon from "./src/prev-arrow.svg";
import CloseIcon from "./src/close-circle.svg";
import Loading from "../../components/loading/Loading";
import AdminNavbar from "../../components/navbar/AdminNavbar";

function AdminCarrousel() {
    const [usuario, setUsuario] = useState(sessionStorage.getItem("admin-user"));

    if (!usuario || usuario === "") {
        window.location.href = "/admincms/login";
    }

    const [showLoading, setShowLoading] = useState(true);
    const [carrousel, setCarrousel] = useState({ images: [] });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASEURL}/carrousel/images`, { method: "GET" }).then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    setCarrousel(data.data);
                    setShowLoading(false);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        })
    }, [])

    return (
        <>
            <AdminNavbar />
            <Loading show={showLoading} />
            <Container>
                <Row className="justify-content-center content-admin-carrousel">
                    <Col lg={11}>
                        <Row className="justify-content-center">
                            <Col lg={8}>
                                <p className="p-titulo-admin">Carrousel de imagenes</p>
                            </Col>
                            <Col lg={3}>
                                <Button className="btn-admin" disabled={showLoading} onClick={() => {

                                    setShowLoading(true);

                                    try {
                                        fetch(`${process.env.REACT_APP_API_BASEURL}/carrousel/images`, {
                                            method: "POST",
                                            headers: {
                                                "Content-Type": "application/json"
                                            },
                                            body: JSON.stringify({ ...carrousel, ultimoUsuario: usuario })
                                        }).then((responseL) => {
                                            if (responseL.ok) {
                                                Swal.fire({
                                                    icon: "success",
                                                    title: "Guardado correctamente",
                                                    text: "Puedes visualizar las imágenes del carrousel en el sitio",
                                                    footer: "<a href='/' target='_blank'>tabsa.cl</a>"
                                                })
                                                .then((alResponse) => {
                                                    setShowLoading(false);
                                                })
                                            }
                                            else {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "No se pudo guardar",
                                                    text: "Favor contacta con el equipo de desarrollo."
                                                })
                                                .then((alResponse) => {
                                                    setShowLoading(false);
                                                })
                                            }
                                        })
                                    }
                                    catch (error) {
                                        Swal.fire({
                                            icon: "error",
                                            title: "No se pudo guardar",
                                            text: `Favor contacta con el equipo de desarrollo. ${error}`
                                        })
                                        .then((alResponse) => {
                                            setShowLoading(false);
                                        })
                                    }
                                    
                                }}>Guardar</Button>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg={5}>
                                <Row>
                                    <Col>
                                        <Form.Label className="label-admin" htmlFor="txtFileL">Imágenes:</Form.Label>
                                        <Form.Control 
                                            className={`form-field-admin`} 
                                            type="file" 
                                            id="txtFileL"
                                            placeholder="Sube la imagen del banner izquierdo aquí."
                                            accept="image/jpeg"
                                            onChange={(evt) => {
                                                if (evt.target.files.length > 0) {

                                                    setShowLoading(true);

                                                    let formData = new FormData();

                                                    formData.append("pdf", evt.target.files[0]);
                                                    
                                                    fetch('https://recursos.tabsa.cl/api/file/save', {
                                                        method: "POST",
                                                        body: formData
                                                    })
                                                    .then(response => {
                                                        if (response.ok) {
                                                            response.json().then(data => {
                                                                let newImages = carrousel.images.concat({ url: data.url });
                                                               
                                                                setCarrousel({ images: newImages.map((im, idx) => ({ url: im.url, order: (idx + 1) }) ) })
                                                                setShowLoading(false);
                                                            })
                                                        }
                                                        else {
                                                            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                            setShowLoading(false);
                                                        }
                                                    })
                                                    .catch(error => {
                                                        console.log(error)
                                                    })

                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6}></Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg={11}>
                                <Carousel nextIcon={<img src={NextIcon} />} prevIcon={<img src={PrevIcon} />}  >
                                    {
                                        carrousel.images.map((img, index) => {
                                            return (
                                                <Carousel.Item key={`${index}-item`} >
                                                    <img src={img.url} alt="slide" className="slider-image" />
                                                    <span className="btn-delete-admin" onClick={() => {

                                                        let newImages = carrousel.images;
                                                        newImages.splice(index, 1);
                                                        setCarrousel({ images: newImages.map((im, idx) => ({ url: im.url, order: (idx + 1) }) ) });

                                                    }}><img src={CloseIcon} /></span>
                                                </Carousel.Item>
                                            )
                                        })
                                    }
                                </Carousel>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AdminCarrousel;