import "./PreguntasFrecuentes.css";
import { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../components/Navegacion/Navegacion";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import Footer from "../../components/Footer/Footer";
import Chat from "../../components/Chat/Chat";
import ImgBack from "./src/img_back.jpg";
import moment from "moment";
import CloseButtonIcon from "./src/close-circle.svg";

import { useTranslation } from "react-i18next";

function PreguntasFrecuentes() {
    const [t, i18n] = useTranslation("global");

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);

    const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    
    let firstDate = moment(new Date()).format("DD-MM-yyyy");
    let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");

    fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
        if (firstDateResponse.ok) {
            firstDateResponse.json().then(firstDateData => { 

                let newCruces = firstDateData.data.map((d) => ({ 
                    estado: d.estado_detalle.toLowerCase(), 
                    origen: d.origen, 
                    destino: d.destino, 
                    hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                    fecha: d.zarpeOriginal.split(" ")[0]
                }))
                setCruces(newCruces);

                fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                    if (secondDateResponse.ok) {
                        secondDateResponse.json().then(secondDateData => { 
                            
                            let newSecondCruces = secondDateData.data.map((d) => ({ 
                                estado: d.estado_detalle.toLowerCase(), 
                                origen: d.origen, 
                                destino: d.destino, 
                                hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                fecha: d.zarpeOriginal.split(" ")[0]
                            }))

                            let concats = newCruces.concat(newSecondCruces);
                            setCruces(concats);

                            let newCrucesSuspendidos = [];
                
                            concats.map((d) => {
                                if (d.estado === "suspendido" || d.estado === "cancelado") {
                                    newCrucesSuspendidos.push(d);
                                }
                            });

                            setCrucesSuspendidos(newCrucesSuspendidos);
                            setShowCruce(newCrucesSuspendidos.length > 0)
                        })
                    }
                    else {
                        console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                    }

                })
            })
        }
        else {
            console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
        }
    }).catch(error => {
      console.log(JSON.stringify(error));
    });

    fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/rutas`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });
  }, [])

  const [chatOpen, setChatOpen] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
    setChatOpen(chat);
    setShowSelectLanguage(idiomas);
    setLoginOpen(login);
  }

    return (
        <div className="PreguntasFrecuentes">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} />
          </div>
          <div className="div-preguntas-frecuentes-s" clickOut={() => { showItems(false, false, false, false, false) }}>
            <img className="img-preguntas-frecuentes-s" alt={"back image"} src={ImgBack} />
          </div>
          <div className="div-preguntas-frecuentes-body" clickOut={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center row-content-pgs-s">
                <Col lg={11} md={11} sm={11} xs={11}>
                    <p className="title-preguntas-frecuentes-s">{t("nosotros.preguntas.titulo")}</p>
                </Col>
              </Row>
              <Row className="justify-content-center row-content-pgs-s">
                <Col lg={11} md={11} sm={11} xs={11}>
                    <ol>
                        <li>
                            <p className="p-preguntas-frecuentes-s">{t("nosotros.preguntas.1.p")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.1.r")} <a className="link-preguntas-frecuentes-s" href="#">{t("nosotros.preguntas.1.link")}</a> {t("nosotros.preguntas.1.r2")}</p>
                        </li>
                        <li>
                            <p className="p-preguntas-frecuentes-s">{t("nosotros.preguntas.2.p")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.2.r")} <a className="link-preguntas-frecuentes-s" href="#">{t("nosotros.preguntas.2.correo")}</a> {t("nosotros.preguntas.2.r2")}</p>
                        </li>
                        <li>
                            <p className="p-preguntas-frecuentes-s">{t("nosotros.preguntas.3.p")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.3.r")} <a className="link-preguntas-frecuentes-s" href="#">{t("nosotros.preguntas.3.correo")}</a></p>
                        </li>
                        <li>
                            <p className="p-preguntas-frecuentes-s">{t("nosotros.preguntas.4.p")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.4.r")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.4.r2")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.4.r3")}</p>
                        </li>
                        <li>
                            <p className="p-preguntas-frecuentes-s">{t("nosotros.preguntas.5.p")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.5.r")} <a className="link-preguntas-frecuentes-s" href="#">{t("nosotros.preguntas.5.correo")}</a> {t("nosotros.preguntas.5.r2")}</p>
                        </li>
                        <li>
                            <p className="p-preguntas-frecuentes-s">{t("nosotros.preguntas.6.p")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.6.r")}</p>
                        </li>
                        <li>
                            <p className="p-preguntas-frecuentes-s">{t("nosotros.preguntas.7.p")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.7.r")} <a className="link-preguntas-frecuentes-s" href="#">{t("nosotros.preguntas.7.correo")}</a> {t("nosotros.preguntas.7.r2")}</p>
                        </li>
                        <li>
                            <p className="p-preguntas-frecuentes-s">{t("nosotros.preguntas.8.p")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.8.r")}</p>
                        </li>
                        <li>
                            <p className="p-preguntas-frecuentes-s">{t("nosotros.preguntas.9.p")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.9.r")}</p>
                        </li>
                        <li>
                            <p className="p-preguntas-frecuentes-s">{t("nosotros.preguntas.10.p")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.10.r")}</p>
                        </li>
                        <li>
                            <p className="p-preguntas-frecuentes-s">{t("nosotros.preguntas.11.p")}</p>
                            <p className="r-preguntas-frecuentes-s">{t("nosotros.preguntas.11.r")} <a className="link-tyc-preguntas-frecuentes-s" onClick={() => setShowModal(true)}>{t("nosotros.preguntas.11.modal")}</a></p>
                        </li>
                    </ol>
                    
                </Col>
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} />
          

          <Modal size="xl" show={showModal}>
            <Modal.Body>
              <img className="img-modal-close-pf" src={CloseButtonIcon} alt="close button" onClick={() => setShowModal(false)} />
              <p className="subp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.titulo")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.p1")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.li1")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.li2")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.li3")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.li4")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.li5")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.li6")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.li7")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.li8")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.li9")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.li10")}</p>
              <p className="modalp-preguntas-frecuentes-s">{t("nosotros.preguntas.modal.li11")}</p>
            </Modal.Body>
          </Modal>
        </div>
    );
}

export default PreguntasFrecuentes;
