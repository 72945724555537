import "./PAPOItinerario.css";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../../components/Navegacion/Navegacion";
import VolverInicio from "../../../components/VolverInicio/VolverInicio";
import Chat from "../../../components/Chat/Chat";
import Footer from "../../../components/Footer/Footer";
import LogoColor from "../src/logo-tabsa-color.png";
import moment from "moment";
import ReactGA from 'react-ga';

import { useTranslation } from "react-i18next";

function PAPOItinerario() {
    const [t, i18n] = useTranslation("global");

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);

    const [viajesA, setViajesA] = useState([]);
    const [viajesB, setViajesB] = useState([]);
    const [ruta, setRuta] = useState({});
    
    useEffect(() => {

      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        location: window.location.href,
      });
      
      fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/ruta/parenas-porvenir`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRuta(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });
  
      let firstDate = moment(new Date()).format("DD-MM-yyyy");
      let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");
  
      fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
          if (firstDateResponse.ok) {
              firstDateResponse.json().then(firstDateData => { 
  
                  let newCruces = firstDateData.data.map((d) => ({ 
                      estado: d.estado_detalle.toLowerCase(), 
                      origen: d.origen, 
                      destino: d.destino, 
                      hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                      fecha: d.zarpeOriginal.split(" ")[0]
                  }))
                  setCruces(newCruces);
  
                  fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                      if (secondDateResponse.ok) {
                          secondDateResponse.json().then(secondDateData => { 
                              
                              let newSecondCruces = secondDateData.data.map((d) => ({ 
                                  estado: d.estado_detalle.toLowerCase(), 
                                  origen: d.origen, 
                                  destino: d.destino, 
                                  hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                  fecha: d.zarpeOriginal.split(" ")[0]
                              }))
  
                              let concats = newCruces.concat(newSecondCruces);
                              setCruces(concats);
  
                              let newCrucesSuspendidos = [];
                  
                              concats.map((d) => {
                                  if (d.estado === "suspendido" || d.estado === "cancelado") {
                                      newCrucesSuspendidos.push(d);
                                  }
                              });
  
                              setCrucesSuspendidos(newCrucesSuspendidos);
                              setShowCruce(newCrucesSuspendidos.length > 0)
                          })
                      }
                      else {
                          console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                      }
  
                  })
              })
          }
          else {
              console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
          }
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
  
      fetch(`https://api-cruce.tabsa.cl/api/tramos`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });
  
      let fechaA = new Date();
      fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaA).format("YYYY-MM")}/1`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {

            if (data && data.data) {

                let dias = data.data.filter((item) => !item.pasado).map((item) => (item.zarpeOriginal.split(" ")[0]));
                dias = dias.filter((item, index) => dias.indexOf(item) === index);

                let itinerario = [];
                
                data.data.filter((item) => !item.pasado).map((item) => {
                    let exists = false;

                    itinerario.map((it) => {
                        if (item.zarpeOriginal.split(" ")[0] === it.fecha) {

                            if (item.id_tramo === "1") {
                                it.idas.push(item.zarpeOriginal.split(" ")[1].substring(0, 5));
                            }
                            else if (item.id_tramo === "2") {
                                it.vueltas.push(item.zarpeOriginal.split(" ")[1].substring(0, 5));
                            }

                            exists = true;
                        }
                    })

                    if (!exists) {
                        itinerario.push({ 
                            fecha: item.zarpeOriginal.split(" ")[0],
                            idas: (item.id_tramo === "1" ? [item.zarpeOriginal.split(" ")[1].substring(0, 5)] : []),
                            vueltas: (item.id_tramo === "2" ? [item.zarpeOriginal.split(" ")[1].substring(0, 5)] : [])  
                        })
                    }
                })
    
                setViajesA(itinerario);
            }
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });
      
      let fechaB = new Date();
      fechaB.setDate(1);
      fechaB.setMonth(fechaB.getMonth() + 1);
      console.log("fechaB: " + moment(fechaB).format("YYYY-MM"));      
      console.log("fechaB: " + fechaB)


      fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaB).format("YYYY-MM")}/1`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {

            if (data && data.data) {

                let dias = data.data.filter((item) => !item.pasado).map((item) => (item.zarpeOriginal.split(" ")[0]));
                dias = dias.filter((item, index) => dias.indexOf(item) === index);

                let itinerario = [];
                
                data.data.filter((item) => !item.pasado).map((item) => {
                    let exists = false;

                    itinerario.map((it) => {
                        if (item.zarpeOriginal.split(" ")[0] === it.fecha) {

                            if (item.id_tramo === "1") {
                                it.idas.push(item.zarpeOriginal.split(" ")[1].substring(0, 5));
                            }
                            else if (item.id_tramo === "2") {
                                it.vueltas.push(item.zarpeOriginal.split(" ")[1].substring(0, 5));
                            }

                            exists = true;
                        }
                    })

                    if (!exists) {
                        itinerario.push({ 
                            fecha: item.zarpeOriginal.split(" ")[0],
                            idas: (item.id_tramo === "1" ? [item.zarpeOriginal.split(" ")[1].substring(0, 5)] : []),
                            vueltas: (item.id_tramo === "2" ? [item.zarpeOriginal.split(" ")[1].substring(0, 5)] : [])  
                        })
                    }
                })
    
                setViajesB(itinerario);
            }
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });

    }, []);
  
    const [chatOpen, setChatOpen] = useState(false);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
  
    function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
      setChatOpen(chat);
      setShowSelectLanguage(idiomas);
      setLoginOpen(login);
    }

    return (
        <div className="PAPOItinerario">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`d-none d-lg-block slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
          </div>
          <div className="div-papo-itinerario-s d-none d-lg-block" onClick={() => { showItems(false, false, false, false, false) }}>
            {
              ruta.imgBack &&
              <img className="img-papo-itinerario-s" alt={"papo-itinerario"} src={ruta.imgBack} />
            }
          </div>
          <div className="div-papo-itinerario-body" onClick={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center d-block d-lg-none">
                <Col lg={6} sm={12} xs={12}>
                  <img alt="logo" className="logo-color-xs" src={LogoColor} />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={11}>
                    <p className="title-papo-itinerario-s">{t("nuestros-servicios.parenas-porvenir.itinerario.titulo")}</p>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={10}>
                    <table className="papo-itinerario-table">
                        <thead>
                            <tr>
                                <td className="day-col"><div>Fecha</div></td>
                                <td className="departure-col"><div>Punta Arenas - Porvenir</div></td>
                                <td className="departure-col"><div>Porvenir - Punta Arenas</div></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                viajesA.map((item, idx) => {
                                  return (
                                    <tr key={`${item.fecha}-a-${idx}`}>
                                        <td>{t(`days.${moment(item.fecha).format("ddd")}`)} | {moment(item.fecha).format("DD-MM-YYYY")}</td>
                                        <td>{item.idas.map((ida, i) => (<p key={`${item.fecha}-a-${idx}-a-${i}`}>{ida} Hrs.</p>))}</td>
                                        <td>{item.vueltas.map((vuelta, i) => (<p key={`${item.fecha}-a-${idx}-b-${i}`}>{vuelta} Hrs.</p>))}</td>
                                    </tr>
                                  )
                                })
                            }
                            {
                                viajesB.map((item, idx) => {
                                  return (
                                    <tr key={`${item.fecha}-b-${idx}`}>
                                        <td>{t(`days.${moment(item.fecha).format("ddd")}`)} | {moment(item.fecha).format("DD-MM-YYYY")}</td>
                                        <td>{item.idas.map((ida, i) => (<p key={`${item.fecha}-b-${idx}-a-${i}`}>{ida} Hrs.</p>))}</td>
                                        <td>{item.vueltas.map((vuelta, i) => (<p key={`${item.fecha}-b-${idx}-b-${i}`}>{vuelta} Hrs.</p>))}</td>
                                    </tr>
                                  )
                                })
                            }
                        </tbody>
                    </table>
                </Col>
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/rutas/parenas-porvenir"} Texto={"volver-inicio.servicios"} />
        </div>
      );
}

export default PAPOItinerario;
