import "./AdminKeys.css";
import { useEffect, useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Loading from "../../components/loading/Loading";
import AdminNavbar from "../../components/navbar/AdminNavbar";

function AdminKeys() {
    const [usuario, setUsuario] = useState(sessionStorage.getItem("admin-user"));

    if (!usuario || usuario === "") {
        window.location.href = "/admincms/login";
    }

    const [showLoading, setShowLoading] = useState(true);
    const [keys, setKeys] = useState({ keys: [] });
    const [nombre, setNombre] = useState("");
    const [key, setKey] = useState("");
    const [descripcion, setDescripcion] = useState("");

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASEURL}/keys/keys`, { method: "GET" }).then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    setKeys({ ...keys, keys: data.data.map(d => ({ ...d, edicion: false })) });
                    setShowLoading(false);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        })
    }, []);

    return (
        <>
            <AdminNavbar />
            <Loading show={showLoading} />
            <Container>
                <Row className="justify-content-center content-admin-keys">
                    <Col lg={11}>
                        <Row className="justify-content-center">
                            <Col lg={10}>
                                <p className="p-titulo-admin">Keys</p>
                            </Col>
                            <Col lg={2}>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg={12}>
                                <table className="table-admin-servicios">
                                    <thead>
                                        <tr>
                                            <td width={"20%"}>Nombre</td>
                                            <td>Key</td>
                                            <td>Descripción</td>
                                            <td width={"10%"}></td>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <td>
                                                <Form.Control 
                                                    className={`form-field-admin form-field-admin-key`}  
                                                    type="text" 
                                                    id="txtCodigo"
                                                    placeholder="Nombre"
                                                    value={nombre}
                                                    onChange={(evt) => {
                                                        setNombre(evt.target.value);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control 
                                                    className={`form-field-admin form-field-admin-key`}  
                                                    type="text" 
                                                    id="txtCodigo"
                                                    placeholder="Key"
                                                    value={key}
                                                    onChange={(evt) => {
                                                        setKey(evt.target.value);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control 
                                                    className={`form-field-admin form-field-admin-key`}  
                                                    type="text" 
                                                    id="txtCodigo"
                                                    placeholder="Descripción"
                                                    value={descripcion}
                                                    onChange={(evt) => {
                                                        setDescripcion(evt.target.value);
                                                    }}
                                                />
                                            </td>
                                            <td width={"10%"}>
                                                <Button className="btn-admin-editar" onClick={() => {

                                                    let keyToSave = { key, nombre, descripcion, ultimoUsuario: usuario };
                                                    console.log(keyToSave);
                                                    fetch(`${process.env.REACT_APP_API_BASEURL}/keys/key`, { 
                                                        method: "POST",
                                                        headers: {
                                                            "Content-Type": "application/json"
                                                        },
                                                        body: JSON.stringify(keyToSave)
                                                    }).then((response) => {
                                                        if (response.ok) {
                                                            setKeys({ ...keys, keys: keys.keys.concat({ nombre, key, descripcion }) });
                                                            setNombre("");
                                                            setKey("");
                                                            setDescripcion("");
                                                        }
                                                        else {
                                                            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                        }
                                                    });
                                                }}>Crear key</Button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                    {
                                        keys.keys.map((r, i) => (
                                            <tr key={`tkeys-${i}`} className={`${i > 0 ? "tr-row": ""}`}>
                                                <td className="bold">
                                                    {r.nombre}
                                                </td>
                                                <td>
                                                    {r.key}
                                                </td>
                                                <td>
                                                    {r.descripcion}
                                                </td>
                                                <td>
                                                    <Button className="btn-admin-eliminar" onClick={() => {
                                                        Swal.fire({
                                                            icon: "info",
                                                            title: "¿Estás seguro de eliminar la key?",
                                                            showDenyButton: true,
                                                            confirmButtonText: "Eliminar",
                                                            denyButtonText: "Cancelar"
                                                        }).then(alResponse => {
                                                            if (alResponse.isConfirmed) {
                                                                setShowLoading(true);

                                                                fetch(`${process.env.REACT_APP_API_BASEURL}/keys/key`, { 
                                                                    method: "DELETE",
                                                                    headers: {
                                                                        "Content-Type": "application/json"
                                                                    },
                                                                    body: JSON.stringify({ key: keys.keys[i].key, ultimoUsuario: usuario })
                                                                }).then((response) => {
                                                                    if (response.ok) {
                                                                        response.json().then(data => {

                                                                            let newKeys = keys.keys;
                                                                            newKeys.splice(i, 1);
                                                                            setKeys({ ...keys, keys: newKeys });

                                                                            setShowLoading(false);
                                                                        })
                                                                    }
                                                                    else {
                                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                    }
                                                                });
                                                            }
                                                        })
                                                    }}>Eliminar</Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AdminKeys;