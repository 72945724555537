import "./Atencion.css";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import EstadoCruce from '../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../components/Navegacion/Navegacion";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import Chat from "../../components/Chat/Chat";
import Footer from "../../components/Footer/Footer";
import LogoColor from "./src/logo-tabsa-color.png";
import CelularSVG from "./src/celular.svg";
import CLSVG from "./src/tabsa_cl.svg";
import FacebookSVG from "./src/tabsa_facebook.svg";
import TwitterSVG from "./src/tabsa_twitter.svg";
import InstagramSVG from "./src/tabsa_instagram.svg";
import moment from "moment";

import { useTranslation } from "react-i18next";

function Atencion() {
    const [t, i18n] = useTranslation("global");
    const { code } = useParams();

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [ruta, setRuta] = useState({
        titulo: { es: "", en: "es" },
        contenido: []
    });

    useEffect(() => {
        
        fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/ruta/${code}`, { method: "GET" }).then(response => {
          if (response.ok) {
            response.json().then(data => {
              setRuta(data.data);
              console.log(data.data.atencion)
            })
          }
          else {
            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
          }
        }).catch(error => {
            console.log(JSON.stringify(error));
        });
  
    }, [code])
    
    useEffect(() => {
  
      let firstDate = moment(new Date()).format("DD-MM-yyyy");
      let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");
  
      fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
          if (firstDateResponse.ok) {
              firstDateResponse.json().then(firstDateData => { 
  
                  let newCruces = firstDateData.data.map((d) => ({ 
                      estado: d.estado_detalle.toLowerCase(), 
                      origen: d.origen, 
                      destino: d.destino, 
                      hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                      fecha: d.zarpeOriginal.split(" ")[0]
                  }))
                  setCruces(newCruces);
  
                  fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                      if (secondDateResponse.ok) {
                          secondDateResponse.json().then(secondDateData => { 
                              
                              let newSecondCruces = secondDateData.data.map((d) => ({ 
                                  estado: d.estado_detalle.toLowerCase(), 
                                  origen: d.origen, 
                                  destino: d.destino, 
                                  hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                  fecha: d.zarpeOriginal.split(" ")[0]
                              }))
  
                              let concats = newCruces.concat(newSecondCruces);
                              setCruces(concats);
  
                              let newCrucesSuspendidos = [];
                  
                              concats.map((d) => {
                                  if (d.estado === "suspendido" || d.estado === "cancelado") {
                                      newCrucesSuspendidos.push(d);
                                  }
                              });
  
                              setCrucesSuspendidos(newCrucesSuspendidos);
                              setShowCruce(newCrucesSuspendidos.length > 0)
                          })
                      }
                      else {
                          console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                      }
  
                  })
              })
          }
          else {
              console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
          }
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
  
    }, []);
  
    const [chatOpen, setChatOpen] = useState(false);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
  
    function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
      setChatOpen(chat);
      setShowSelectLanguage(idiomas);
      setLoginOpen(login);
    }

    return (
        <div className="CMSIAtencion">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`d-none d-lg-block slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
          </div>
          <div className="div-cmsi-atencion-s d-none d-lg-block" onClick={() => { showItems(false, false, false, false, false) }}>
            {
              ruta.imgBack &&
              <img className="img-services-s" alt={ruta.code} src={ruta.imgBack} />
            }
          </div>
          <div className="div-cmsi-ii-body" onClick={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center d-block d-lg-none align-center">
                <Col lg={6} sm={12} xs={12}>
                  <img alt="logo" className="logo-color-xs" src={LogoColor} />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={11}>
                    <Row>
                        <Col lg={12}>
                            <p className="title-cmsi-atencion-s align-left">{t("nuestros-servicios.pyungay-pnatales.atencion.titulo")}</p>
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={11} sm={12} xs={12}>
                    <Row className="justify-content-center">
                        {
                            ruta.atencion && ruta.atencion.length > 0 &&
                            ruta.atencion.map((s, idx) => {
                                if (s.tabla) {
                                    return (
                                        <Col lg={s.col} sm={12} xs={12} className="cmsi-padd-col-atencion">
                                            {
                                                s.tabla.map((tbl, tblIdx) => {
                                                    if (tbl.titulo) {
                                                        return (
                                                            <table className="cmsi-atencion-table">
                                                                <thead key={`tbl-${idx}-head-titulo-${tblIdx}`}>
                                                                    <tr className="cmsi-atencion-table-title">
                                                                        <td colSpan={tbl.span}>
                                                                            {tbl.titulo[t("lang")]}
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        )
                                                    }
                                                    else if (tbl.subtitulos) {
                                                        return (
                                                            <table className="cmsi-atencion-table">
                                                                <thead key={`tbl-${idx}-head-subtitulo-${tblIdx}`}>
                                                                    <tr className="cmsi-atencion-table-subtitle">
                                                                        {tbl.subtitulos.map((sub, subIdx) => (<td key={`tbl-${idx}-head-subtitulo-${tblIdx}-td-${subIdx}`} colSpan={tbl.span}>{sub[t("lang")]}</td>))}
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        )
                                                    }
                                                    else if (tbl.body) {
                                                        return (
                                                            <table className="cmsi-atencion-table">
                                                                <tbody key={`tbl-${idx}-body-${tblIdx}`}>
                                                                    <tr>
                                                                        {
                                                                            tbl.body.map((bd, bdIdx) => {
                                                                                return (
                                                                                    <td key={`tbl-${idx}-body-${tblIdx}-td-${bdIdx}`} width={`${(100 / tbl.body.length)}%`}>
                                                                                        {
                                                                                            bd.map((txt, txtIdx) => {
                                                                                                if (txtIdx === 0) {
                                                                                                    return (txt[t("lang")])
                                                                                                }
                                                                                                else {
                                                                                                    return (<>
                                                                                                        <br />
                                                                                                        {txt[t("lang")]}
                                                                                                    </>)
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        )
                                                    }
                                                    else if (tbl.footer) {
                                                        return (
                                                            <table className="cmsi-atencion-table">
                                                                <tfoot key={`tbl-${idx}-body-${tblIdx}`}>
                                                                    <tr>
                                                                        <td key={`tbl-${idx}-footer-${tblIdx}-td`} colSpan={tbl.span}>
                                                                            <br />
                                                                            <ul>
                                                                            {
                                                                                tbl.footer.map((ft, ftIdx) => {
                                                                                    return (<li key={`tbl-${idx}-footer-${tblIdx}-li-${ftIdx}`}>{ft[t("lang")]}</li>)
                                                                                })
                                                                            }
                                                                            </ul>
                                                                            <br />
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        )
                                                    }
                                                })
                                            }
                                        </Col>
                                    )
                                }
                                else if (s.masInfo) {
                                    return (
                                        <Col lg={s.masInfo.col} sm={12} xs={12} className="cmsi-padd-col-atencion">
                                            {
                                                s.masInfo.col === 6 &&
                                                <>
                                                    <Row className="justify-content-center">
                                                        <Col lg={8} sm={4} xs={4}>
                                                            <div className="cmsi-atencion-div-info">
                                                                <Row className="justify-content-center">
                                                                    <Col lg={12}>
                                                                        <p className="cmsi-atencion-p-info">
                                                                            {t("nuestros-servicios.parenas-pwilliams.atencion.mas-info")}
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="justify-content-center">
                                                                    <Col lg={12}>
                                                                        <p className="cmsi-atencion-p-info"><img className="cmsi-phone" src={CelularSVG} alt="phone" /> 612 728100</p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col lg={12} sm={5} xs={5}>
                                                            <img src={CLSVG} alt="tabsa.cl" className="cmsi-atencion-cl-image" />
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col lg={12} sm={5} xs={5}>
                                                            <img src={FacebookSVG} className="cmsi-atencion-link-image" alt="facebook" onClick={() => { window.location.href = "https://web.facebook.com/TABSA"}} />
                                                            <img src={TwitterSVG} className="cmsi-atencion-link-image" alt="x" onClick={() => { window.location.href = "https://twitter.com/_tabsa"}} />
                                                            <img src={InstagramSVG} className="cmsi-atencion-link-image" alt="instagram" onClick={() => { window.location.href = "https://www.instagram.com/_tabsa/"}} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                            {
                                                s.masInfo.col === 12 &&
                                                <Row className="justify-content-center">
                                                    <Col lg={6} sm={6} xs={6}>
                                                        <Row className="justify-content-center">
                                                            <Col lg={2}>
                                                            </Col>
                                                            <Col lg={8} sm={4} xs={4}>
                                                                <div className="cmsi-atencion-div-info">
                                                                    <Row className="justify-content-center">
                                                                        <Col lg={12}>
                                                                            <p className="cmsi-atencion-p-info">
                                                                                {t("nuestros-servicios.parenas-pwilliams.atencion.mas-info")}
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="justify-content-center">
                                                                        <Col lg={12}>
                                                                            <p className="cmsi-atencion-p-info"><img className="cmsi-phone" src={CelularSVG} alt="phone" /> 612 728100</p>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={6} sm={6} xs={6}>
                                                        <Row className="justify-content-center">
                                                            <Col lg={12} sm={5} xs={5}>
                                                                <img src={CLSVG} alt="tabsa.cl" className="cmsi-atencion-cl-image" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="justify-content-center">
                                                            <Col lg={12} sm={5} xs={5}>
                                                                <img src={FacebookSVG} className="cmsi-atencion-link-image" alt="facebook" onClick={() => { window.location.href = "https://web.facebook.com/TABSA"}} />
                                                                <img src={TwitterSVG} className="cmsi-atencion-link-image" alt="x" onClick={() => { window.location.href = "https://twitter.com/_tabsa"}} />
                                                                <img src={InstagramSVG} className="cmsi-atencion-link-image" alt="instagram" onClick={() => { window.location.href = "https://www.instagram.com/_tabsa/"}} />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                    )
                                }
                                else if (s.listaImagen) {
                                    return (
                                        <Col lg={12} md={11} className="cmsi-padd-col-atencion">
                                            <Row>
                                                <Col lg={6} md={11} sm={12} xs={12}>
                                                    {
                                                        s.listaImagen.textos && s.listaImagen.textos.length > 0 &&
                                                        s.listaImagen.textos.map((textos) => {
                                                            if (textos.titulo) {
                                                                return (<p className="p-sub-cmsi-atencion-s">{textos.titulo[t("lang")]}</p>)
                                                            }
                                                            else if (textos.texto) {
                                                                return (<p className="p-cmsi-atencion-s cmsi-atencion-justify">{textos.texto[t("lang")]}</p>)
                                                            }
                                                            else if (textos.listas) {
                                                                return (<ul>
                                                                    {
                                                                        textos.listas.map((lista) => (<li className="p-cmsi-atencion-s cmsi-atencion-justify">{lista[t("lang")]}</li>))
                                                                    }
                                                                </ul>)
                                                            }
                                                        })
                                                    }
                                                </Col>
                                                <Col lg={{ span: 6, offset: 0 }} md={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                                                    <Row className="no-margin">
                                                        <Col className="no-margin" lg={12} md={12} sm={12} xs={12}>
                                                            {
                                                                s.listaImagen.imgT &&
                                                                <img src={s.listaImagen.imgT} className="atencion-img-t" alt="ferry" />
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className="no-margin">
                                                        <Col className="no-margin" lg={8} md={8} sm={8} xs={8}>
                                                            {
                                                                s.listaImagen.imgBL &&
                                                                <img src={s.listaImagen.imgBL} className="atencion-img-b-l" alt="ferry" />
                                                            }
                                                        </Col>
                                                        <Col className="no-margin" lg={4} md={4} sm={4} xs={4}>
                                                            <Row className="no-margin">
                                                                <Col className="no-margin" lg={12}>
                                                                    {
                                                                        s.listaImagen.imgCR &&
                                                                        <img src={s.listaImagen.imgCR} className="atencion-img-c-r" alt="ferry" />
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <Row className="no-margin">
                                                                <Col className="no-margin" lg={12}>
                                                                    {
                                                                        s.listaImagen.imgBR &&
                                                                        <img src={s.listaImagen.imgBR} className="atencion-img-b-r" alt="ferry" />
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                }
                                else if (s.lista) {
                                    return (
                                        <Col lg={12} className="cmsi-padd-col-atencion">
                                            <Row>
                                                <Col lg={12} md={11} sm={11} xs={11}>
                                                    {
                                                        s.lista.textos && s.lista.textos.length > 0 &&
                                                        s.lista.textos.map((textos) => {
                                                            if (textos.titulo) {
                                                                return (<p className="p-sub-cmsi-atencion-s">{textos.titulo[t("lang")]}</p>)
                                                            }
                                                            else if (textos.texto) {
                                                                return (<p className="p-cmsi-atencion-s cmsi-atencion-justify">{textos.texto[t("lang")]}</p>)
                                                            }
                                                            else if (textos.listas) {
                                                                return (<ul>
                                                                    {
                                                                        textos.listas.map((lista) => (<li className="p-cmsi-atencion-s cmsi-atencion-justify">{lista[t("lang")]}</li>))
                                                                    }
                                                                </ul>)
                                                            }
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                }
                            })
                        }
                    </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={`/rutas/${code}`} Texto={"volver-inicio.servicios"} />
        </div>
    )
}

export default Atencion;