import "./AdminBanners.css";
import { useState, useEffect } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import AdminNavbar from "../../components/navbar/AdminNavbar";
import Swal from "sweetalert2";
import Loading from "../../components/loading/Loading";

function AdminBanners() {
    const [usuario, setUsuario] = useState(sessionStorage.getItem("admin-user"));

    if (!usuario || usuario === "") {
        window.location.href = "/admincms/login";
    }

    const [bannerL, setBannerL] = useState({ position: "l" });
    const [bannerR, setBannerR] = useState({ position: "r" });

    const [savedDisabled, setSavedDisabled] = useState(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASEURL}/Banners/banners`, { method: "GET" }).then((response) => {
            if (response.ok) {
                response.json().then(data => {

                    if (data.data.filter(b => b.position === "l").length > 0) {
                        setBannerL(data.data.filter(b => b.position === "l")[0]);
                    }
                    if (data.data.filter(b => b.position === "r").length > 0) {
                        setBannerR(data.data.filter(b => b.position === "r")[0]);
                    }
                    setSavedDisabled(false);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        })
    }, [])

    return (
        <>
            <AdminNavbar />
            <Loading show={savedDisabled} />
            <Container>
                <Row className="justify-content-center content-admin">
                    <Col lg={11}>
                        <Row className="justify-content-center">
                            <Col lg={8}>
                                <p className="p-titulo-admin">Banners</p>
                            </Col>
                            <Col lg={3}>
                                <Button className="btn-admin" disabled={savedDisabled} onClick={() => {

                                    setSavedDisabled(true);

                                    try {
                                        fetch(`${process.env.REACT_APP_API_BASEURL}/Banners/banners`, {
                                            method: "POST",
                                            headers: {
                                                "Content-Type": "application/json"
                                            },
                                            body: JSON.stringify({ ...bannerL, ultimoUsuario: usuario })
                                        }).then((responseL) => {
                                            if (responseL.ok) {
                                                fetch(`${process.env.REACT_APP_API_BASEURL}/Banners/banners`, {
                                                    method: "POST",
                                                    headers: {
                                                        "Content-Type": "application/json"
                                                    },
                                                    body: JSON.stringify({ ...bannerR, ultimoUsuario: usuario })
                                                }).then((responseR) => {
                                                    if (responseR.ok) {
                                                        Swal.fire({
                                                            icon: "success",
                                                            title: "Guardado correctamente",
                                                            text: "Puedes visualizar los banners en el sitio",
                                                            footer: "<a href='/' target='_blank'>tabsa.cl</a>"
                                                        })
                                                        .then((alResponse) => {
                                                            setSavedDisabled(false);
                                                        })
                                                    }
                                                    else {
                                                        Swal.fire({
                                                            icon: "error",
                                                            title: "No se pudo guardar",
                                                            text: "Favor contacta con el equipo de desarrollo."
                                                        })
                                                        .then((alResponse) => {
                                                            setSavedDisabled(false);
                                                        })
                                                    }
                                                })
                                            }
                                            else {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "No se pudo guardar",
                                                    text: "Favor contacta con el equipo de desarrollo."
                                                })
                                                .then((alResponse) => {
                                                    setSavedDisabled(false);
                                                })
                                            }
                                        })
                                    }
                                    catch (error) {
                                        Swal.fire({
                                            icon: "error",
                                            title: "No se pudo guardar",
                                            text: `Favor contacta con el equipo de desarrollo. ${error}`
                                        })
                                        .then((alResponse) => {
                                            setSavedDisabled(false);
                                        })
                                    }
                                    
                                }}>Guardar</Button>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg={5}>
                                <Row>
                                    <Col>
                                        <Form.Label className="label-admin" htmlFor="txtFileL">Banner izquierdo</Form.Label>
                                        <Form.Control 
                                            className={`form-field-admin`} 
                                            type="file" 
                                            id="txtFileL"
                                            placeholder="Sube la imagen del banner izquierdo aquí."
                                            accept="image/jpeg"
                                            onChange={(evt) => {
                                                if (evt.target.files.length > 0) {

                                                    setSavedDisabled(true);

                                                    let formData = new FormData();

                                                    formData.append("pdf", evt.target.files[0]);
                                                    
                                                    fetch('https://recursos.tabsa.cl/api/file/save', {
                                                        method: "POST",
                                                        body: formData
                                                    })
                                                    .then(response => {
                                                        if (response.ok) {
                                                            response.json().then(data => {
                                                                setBannerL({ ...bannerL, img: data.url, file: evt.target.files[0], fileURL: URL.createObjectURL(evt.target.files[0]) })
                                                                setSavedDisabled(false);
                                                            })
                                                        }
                                                        else {
                                                            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                            setSavedDisabled(false);
                                                        }
                                                    })
                                                    .catch(error => {
                                                        console.log(error)
                                                    })

                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className="label-admin" htmlFor="txtURLL">Link (URL)</Form.Label>
                                        <Form.Control 
                                            className={`form-field-admin`}  
                                            type="text" 
                                            id="txtURLL"
                                            placeholder="Ingresa el link donde el banner redireccionará (Opcional)"
                                            value={bannerL.url}
                                            onChange={(evt) => {
                                                setBannerL({ ...bannerL, url: evt.target.value })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {
                                    bannerL && (bannerL.fileURL || bannerL.img) &&
                                    <img src={(bannerL.fileURL || bannerL.img)} 
                                        className={`img-admin ${(bannerL.url && bannerL.url.trim().length > 0) ? "img-admin-clickable" : ""}`} 
                                        onClick={() => {
                                            if (bannerL.url && bannerL.url.trim().length > 0) {
                                                window.open(bannerL.url, "_blank")
                                            }
                                        }}
                                    />
                                }
                                {
                                    bannerL && (bannerL.fileURL || bannerL.img) && bannerL.url && bannerL.url.trim().length > 0 &&
                                    <span className="label-detalle-admin">*Prueba el haciendo click en la imagen del banner de ejemplo.</span>
                                }
                            </Col>
                            <Col lg={1}></Col>
                            <Col lg={5}>
                                <Row>
                                    <Col>
                                        <Form.Label className="label-admin" htmlFor="txtFileR">Banner Derecho</Form.Label>
                                        <Form.Control 
                                            className={`form-field-admin`} 
                                            type="file" 
                                            id="txtFileR"
                                            placeholder="Sube la imagen del banner izquierdo aquí."
                                            accept="image/png, image/jpeg"
                                            onChange={(evt) => {
                                                if (evt.target.files.length > 0) {

                                                    setSavedDisabled(true);

                                                    let formData = new FormData();

                                                    formData.append("pdf", evt.target.files[0]);
                                                    
                                                    fetch('https://recursos.tabsa.cl/api/file/save', {
                                                        method: "POST",
                                                        body: formData
                                                    })
                                                    .then(response => {
                                                        if (response.ok) {
                                                            response.json().then(data => {
                                                                setBannerR({ ...bannerR, img: data.url, file: evt.target.files[0], fileURL: URL.createObjectURL(evt.target.files[0]) })
                                                                setSavedDisabled(false);
                                                            })
                                                        }
                                                        else {
                                                            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                            setSavedDisabled(false);
                                                        }
                                                    })
                                                    .catch(error => {
                                                        console.log(error)
                                                    })
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className="label-admin" htmlFor="txtURLL">Link (URL)</Form.Label>
                                        <Form.Control 
                                            className={`form-field-admin`}  
                                            type="text" 
                                            id="txtURLR"
                                            placeholder="Ingresa el link donde el banner redireccionará (Opcional)"
                                            value={bannerR.url}
                                            onChange={(evt) => {
                                                setBannerR({ ...bannerR, url: evt.target.value })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {
                                    bannerR && (bannerR.fileURL || bannerR.img) &&
                                    <img src={(bannerR.fileURL || bannerR.img)} 
                                        className={`img-admin ${(bannerR.url && bannerR.url.trim().length > 0) ? "img-admin-clickable" : ""}`} 
                                        onClick={() => {
                                            if (bannerR.url && bannerR.url.trim().length > 0) {
                                                window.open(bannerR.url, "_blank")
                                            }
                                        }}
                                    />
                                }
                                {
                                    bannerR && (bannerR.fileURL || bannerR.img) && bannerR.url && bannerR.url.trim().length > 0 &&
                                    <span className="label-detalle-admin">*Prueba el haciendo click en la imagen del banner de ejemplo.</span>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AdminBanners;