import "./Services.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../components/EstadoCruce/EstadoCruce';
import Navegacion from "../components/Navegacion/Navegacion";
import VolverInicio from "../components/VolverInicio/VolverInicio";
import Chat from "../components/Chat/Chat";
import Footer from "../components/Footer/Footer";
import LogoColor from "./src/logo-tabsa-color.png";
import moment from "moment";

import { useTranslation } from "react-i18next";

function Services() {
    const [t, i18n] = useTranslation("global");

    const { code } = useParams();
    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [servicio, setServicio] = useState({
      titulo: { es: "", en: "es" },
      contenido: []
    });

    useEffect(() => {
      
      fetch(`${process.env.REACT_APP_API_BASEURL}/Servicios/servicio/${code}`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setServicio(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });

    }, [code])
    
    useEffect(() => {
  
      let firstDate = moment(new Date()).format("DD-MM-yyyy");
      let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");
  
      fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
          if (firstDateResponse.ok) {
              firstDateResponse.json().then(firstDateData => { 
  
                  let newCruces = firstDateData.data.map((d) => ({ 
                      estado: d.estado_detalle.toLowerCase(), 
                      origen: d.origen, 
                      destino: d.destino, 
                      hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                      fecha: d.zarpeOriginal.split(" ")[0]
                  }))
                  setCruces(newCruces);
  
                  fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                      if (secondDateResponse.ok) {
                          secondDateResponse.json().then(secondDateData => { 
                              
                              let newSecondCruces = secondDateData.data.map((d) => ({ 
                                  estado: d.estado_detalle.toLowerCase(), 
                                  origen: d.origen, 
                                  destino: d.destino, 
                                  hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                  fecha: d.zarpeOriginal.split(" ")[0]
                              }))
  
                              let concats = newCruces.concat(newSecondCruces);
                              setCruces(concats);
  
                              let newCrucesSuspendidos = [];
                  
                              concats.map((d) => {
                                  if (d.estado === "suspendido" || d.estado === "cancelado") {
                                      newCrucesSuspendidos.push(d);
                                  }
                              });
  
                              setCrucesSuspendidos(newCrucesSuspendidos);
                              setShowCruce(newCrucesSuspendidos.length > 0)
                          })
                      }
                      else {
                          console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                      }
  
                  })
              })
          }
          else {
              console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
          }
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
  
    }, []);
  
    const [chatOpen, setChatOpen] = useState(false);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
  
    function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
      setChatOpen(chat);
      setShowSelectLanguage(idiomas);
      setLoginOpen(login);
    }

    return (
        <div className="Services">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`d-none d-lg-block slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
          </div>
          <div className="div-services-s d-none d-lg-block" onClick={() => { showItems(false, false, false, false, false) }}>
            {
              servicio.imgBack &&
              <img className="img-services-s" alt={servicio.code} src={servicio.imgBack} />
            }
          </div>
          <div className="div-services-body" onClick={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center d-block d-lg-none">
                <Col lg={6} sm={12} xs={12}>
                  <img alt="logo" className="logo-color-xs" src={LogoColor} />
                </Col>
              </Row>
              <Row className="justify-content-center row-services-content">
                <Col lg={11}>
                    <Row>
                        <Col lg={6}>
                            <p className="title-services-s">{servicio.titulo[t("lang")]}</p>
                            {
                              servicio.contenido.map(c => (<p className="p-services-s">{c[t("lang")]}</p>))
                            }
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col lg={12}>
                                  {
                                    servicio.imgL &&
                                    <img className="img-services-t-l" src={servicio.imgL} alt="transferencia de prácticos t-l" />
                                  }
                                  {
                                    servicio.imgR &&
                                    <img className="img-services-t-r" src={servicio.imgR} alt="transferencia de prácticos t-r" />
                                  }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} />
        </div>
      );
}

export default Services;
