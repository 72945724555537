import "./Ferry.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../components/Navegacion/Navegacion";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import Chat from "../../components/Chat/Chat";
import Footer from "../../components/Footer/Footer";
import LogoColor from "./src/logo-tabsa-color.png";
import moment from "moment";

import { useTranslation } from "react-i18next";

function Ferry() {
    const [t, i18n] = useTranslation("global");
    const { code } = useParams()

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [ruta, setRuta] = useState({});
  
    useEffect(() => {
      
      fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/ruta/${code}`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
              setRuta(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });

    }, [code])
    
    useEffect(() => {
  
      let firstDate = moment(new Date()).format("DD-MM-yyyy");
      let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");
  
      fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
          if (firstDateResponse.ok) {
              firstDateResponse.json().then(firstDateData => { 
  
                  let newCruces = firstDateData.data.map((d) => ({ 
                      estado: d.estado_detalle.toLowerCase(), 
                      origen: d.origen, 
                      destino: d.destino, 
                      hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                      fecha: d.zarpeOriginal.split(" ")[0]
                  }))
                  setCruces(newCruces);
  
                  fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                      if (secondDateResponse.ok) {
                          secondDateResponse.json().then(secondDateData => { 
                              
                              let newSecondCruces = secondDateData.data.map((d) => ({ 
                                  estado: d.estado_detalle.toLowerCase(), 
                                  origen: d.origen, 
                                  destino: d.destino, 
                                  hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                  fecha: d.zarpeOriginal.split(" ")[0]
                              }))
  
                              let concats = newCruces.concat(newSecondCruces);
                              setCruces(concats);
  
                              let newCrucesSuspendidos = [];
                  
                              concats.map((d) => {
                                  if (d.estado === "suspendido" || d.estado === "cancelado") {
                                      newCrucesSuspendidos.push(d);
                                  }
                              });
  
                              setCrucesSuspendidos(newCrucesSuspendidos);
                              setShowCruce(newCrucesSuspendidos.length > 0)
                          })
                      }
                      else {
                          console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                      }
  
                  })
              })
          }
          else {
              console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
          }
      }).catch(error => {
        console.log(JSON.stringify(error));
      });

    }, []);
  
    const [chatOpen, setChatOpen] = useState(false);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
  
    function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
      setChatOpen(chat);
      setShowSelectLanguage(idiomas);
      setLoginOpen(login);
    }

    return (
        <div className="CMSIFerry">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`d-none d-lg-block slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
          </div>
          <div className="div-cmsi-ferry-s d-none d-lg-block" onClick={() => { showItems(false, false, false, false, false) }}>
            {
                ruta.imgBack &&
                <img className="img-cmsi-ferry-s" alt={"cmsi-ferry"} src={ruta.imgBack} />
            }
          </div>
          <div className="div-cmsi-ferry-body" onClick={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center d-block d-lg-none align-center">
                <Col lg={6} sm={12} xs={12}>
                  <img alt="logo" className="logo-color-xs" src={LogoColor} />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={11}>
                    <Row>
                        <Col lg={6}>
                            <table className="cmsi-ferry-table">
                                <thead>
                                    <tr>
                                        <td colSpan={2}>{t("nuestros-servicios.primera-angostura.ferry.titulo")}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        ruta.ferry && ruta.ferry.servicios && ruta.ferry.servicios.map((s, i) => (
                                            <tr key={`row-fserv-${i}`}>
                                                <td style={{ width: "50%" }}>{s.servicio[t("lang")]}</td>
                                                <td>{s.descripcion[t("lang")]}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <br />
                            <br />
                            {
                                ruta.ferry && ruta.ferry.detalles && ruta.ferry.detalles.map(d => (
                                    <p className="p-cmsi-ferry-s">{d[t("lang")]}</p>
                                ))
                            }
                        </Col>
                        <Col lg={{ span: 6, offset: 0 }} md={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                            <Row className="no-margin">
                                <Col className="no-margin" lg={12} xs={12}>
                                    {
                                        ruta.ferry && ruta.ferry.imgT &&
                                        <img src={ruta.ferry.imgT} className="ferry-m-img-t" alt="ferry" />
                                    }
                                </Col>
                            </Row>
                            <Row className="no-margin">
                                <Col className="no-margin" lg={8} xs={8}>
                                    {
                                        ruta.ferry && ruta.ferry.imgBL &&
                                        <img src={ruta.ferry.imgBL} className="ferry-m-img-b-l" alt="ferry" />
                                    }
                                </Col>
                                <Col className="no-margin" lg={4} xs={4}>
                                    <Row className="no-margin">
                                        <Col className="no-margin" lg={12}>
                                            {
                                                ruta.ferry && ruta.ferry.imgCR &&
                                                <img src={ruta.ferry.imgCR} className="ferry-m-img-c-r" alt="ferry" />
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="no-margin">
                                        <Col className="no-margin" lg={12}>
                                            {
                                                ruta.ferry && ruta.ferry.imgBR &&
                                                <img src={ruta.ferry.imgBR} className="ferry-m-img-b-r" alt="ferry" />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={`/rutas/${code}`} Texto={"volver-inicio.servicios"} />
        </div>
      );
}

export default Ferry;
