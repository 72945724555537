import "./AdminRutas.css";
import { useEffect, useState } from "react";
import { Container, Col, Row, Form, Button, Card, Tabs, Tab } from "react-bootstrap";
import Swal from "sweetalert2";
import Loading from "../../components/loading/Loading";
import AdminNavbar from "../../components/navbar/AdminNavbar";
import GoogleMap from "../../../components/GoogleMap/GoogleMap";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import CelularSVG from "./src/celular.svg";
import CLSVG from "./src/tabsa_cl.svg";
import FacebookSVG from "./src/tabsa_facebook.svg";
import TwitterSVG from "./src/tabsa_twitter.svg";
import InstagramSVG from "./src/tabsa_instagram.svg";

import { useTranslation } from "react-i18next";

function AdminRutas() {
    const [t, i18n] = useTranslation("global");
    const [usuario, setUsuario] = useState(sessionStorage.getItem("admin-user"));

    if (!usuario || usuario === "") {
        window.location.href = "/admincms/login";
    }

    const [showPreview, setShowPreview] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [showEdicion, setShowEdicion] = useState({ nuevo: true, show: false });
    const [showMap, setShowMap] = useState(true);
    const [newLatitud, setNewLatitud] = useState("");
    const [newLongitud, setNewLongitud] = useState("");
    const [newServicio, setNewServicio] = useState({ servicio: { es: "", en: "" }, descripcion: { es: "", en: "" } });
    const [rutas, setRutas] = useState([]);
    const [ruta, setRuta] = useState({ code: "", contenido: [], cardTitulo: { es: "", en: "" }, cardDetalle: { es: "", en: "" }, navbar: { es: "", en: "" }, ferry: { servicios: [], detalles: [] } });

    const [newAtencionTabla, setNewAtencionTabla] = useState({ tabla: [], col: 12 });
    const [newAtencionLista, setNewAtencionLista] = useState({ lista: { textos: [] } });
    const [newAtencionListaImagen, setNewAtencionListaImagen] = useState({ listaImagen: { textos: [] } });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASEURL}/rutas/rutas`, { method: "GET" }).then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    setRutas(data.data);
                    setShowLoading(false);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        })
    }, [])

    return (
        <>
            <AdminNavbar />
            <Loading show={showLoading} />
            <Container>
                {
                    !showEdicion.show && !showPreview &&
                    <Row className="justify-content-center content-admin-carrousel">
                        <Col lg={11}>
                            <Row className="justify-content-center">
                                <Col lg={10}>
                                    <p className="p-titulo-admin">Rutas</p>
                                </Col>
                                <Col lg={2}>
                                    <Button className="btn-admin" onClick={() => {
                                        setShowEdicion({ nuevo: true, show: true });
                                        setNewLatitud("");
                                        setNewLongitud("");
                                        setNewServicio({ servicio: { es: "", en: "" }, descripcion: { es: "", en: "" } });
                                        setRuta({ code: "", contenido: [], cardTitulo: { es: "", en: "" }, cardDetalle: { es: "", en: "" }, navbar: { es: "", en: "" }, ferry: { servicios: [], detalles: [] } })
                                    }}>Crear nuevo</Button>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <table className="table-admin-servicios">
                                        <thead>
                                            <tr>
                                                <td>Código</td>
                                                <td>Título (ES)</td>
                                                <td>Título (EN)</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            rutas.map((r, i) => (
                                                <tr key={`tserv-${i}`} className={`${i > 0 ? "tr-row": ""}`}>
                                                    <td className="bold">{r.code}</td>
                                                    <td>{r.titulo.es}</td>
                                                    <td>{r.titulo.en}</td>
                                                    <td>
                                                        <Button className="btn-admin-editar" onClick={() => {
                                                            setShowLoading(true);
                                                            setShowEdicion({ nuevo: false, show: true });
                                                            
                                                            fetch(`${process.env.REACT_APP_API_BASEURL}/rutas/ruta/${r.code}`, { method: "GET"}).then((response) => {
                                                                if (response.ok) {
                                                                    response.json().then(data => {
                                                                        setRuta(data.data);
                                                                        setNewLatitud("");
                                                                        setNewLongitud("");
                                                                        setNewServicio({ servicio: { es: "", en: "" }, descripcion: { es: "", en: "" } });
                                                                        setShowLoading(false);
                                                                    })
                                                                }
                                                                else {
                                                                    console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                }
                                                            })

                                                        }}>Editar</Button>
                                                    </td>
                                                    <td>
                                                        <Button className="btn-admin-eliminar" onClick={() => {
                                                            Swal.fire({
                                                                icon: "info",
                                                                title: "¿Estás seguro de eliminar el servicio?",
                                                                showDenyButton: true,
                                                                confirmButtonText: "Eliminar",
                                                                denyButtonText: "Cancelar"
                                                            }).then(alResponse => {
                                                                if (alResponse.isConfirmed) {

                                                                    setShowLoading(true);

                                                                    fetch(`${process.env.REACT_APP_API_BASEURL}/rutas/ruta`, { 
                                                                        method: "DELETE",
                                                                        headers: {
                                                                            "Content-Type": "application/json"
                                                                        },
                                                                        body: JSON.stringify({ code: r.code, ultimoUsuario: usuario })
                                                                    }).then((response) => {
                                                                        if (response.ok) {
                                                                            response.json().then(data => {
                                                                                setRutas(rutas.filter(t => t.code !== r.code))

                                                                                Swal.fire({
                                                                                    icon: "success",
                                                                                    title: "Eliminado correctamente",
                                                                                    text: "Puedes visualizar el servicio en el sitio",
                                                                                    footer: "<a href='/' target='_blank'>tabsa.cl</a>"
                                                                                })
                                                                                .then((alResponse) => {
                                                                                    setShowLoading(false);
                                                                                })
                                                                            })
                                                                        }
                                                                        else {
                                                                            Swal.fire({
                                                                                icon: "error",
                                                                                title: "No se pudo guardar",
                                                                                text: "Favor contacta con el equipo de desarrollo."
                                                                            })
                                                                            .then((alResponse) => {
                                                                                setShowLoading(false);
                                                                            })
                                                                            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                        }
                                                                    })
                                                                }
                                                                else if (alResponse.isDenied) {
                                                                }
                                                            })
                                                        }}>Eliminar</Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {
                    showEdicion.show && !showPreview &&
                    <Row className="justify-content-center content-admin-carrousel">
                        <Col lg={11}>
                            <Row className="justify-content-center">
                                <Col lg={2}>
                                    <Button className="btn-admin-back" onClick={() => {
                                        setShowEdicion({ nuevo: true, show: false });
                                    }}>Volver</Button>
                                </Col>
                                <Col lg={8}>
                                </Col>
                                <Col lg={2}>
                                    <Button className="btn-admin" onClick={() => {
                                        setShowLoading(true);
                                        setShowEdicion({ nuevo: false, show: true });
                                        
                                        try 
                                        {
                                            fetch(`${process.env.REACT_APP_API_BASEURL}/rutas/ruta`, { 
                                                method: "POST",
                                                headers: {
                                                    "Content-Type": "application/json"
                                                },
                                                body: JSON.stringify({ ...ruta, ultimoUsuario: usuario })
                                            }).then((response) => {
                                                if (response.ok) {
                                                    response.json().then(data => {
                                                        setRuta(data.data);
                                                        Swal.fire({
                                                            icon: "success",
                                                            title: "Guardado correctamente",
                                                            text: "Puedes visualizar el servicio en el sitio",
                                                            footer: "<a href='/' target='_blank'>tabsa.cl</a>"
                                                        })
                                                        .then((alResponse) => {
                                                            setShowLoading(false);
                                                        })
                                                    })
                                                }
                                                else {
                                                    Swal.fire({
                                                        icon: "error",
                                                        title: "No se pudo guardar",
                                                        text: "Favor contacta con el equipo de desarrollo."
                                                    })
                                                    .then((alResponse) => {
                                                        setShowLoading(false);
                                                    })
                                                    console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                }
                                            })
                                        }
                                        catch (error) {
                                            Swal.fire({
                                                icon: "error",
                                                title: "No se pudo guardar",
                                                text: `Favor contacta con el equipo de desarrollo. ${error}`
                                            })
                                            .then((alResponse) => {
                                                setShowLoading(false);
                                            })
                                        }
                                    }}>Guardar</Button>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={4}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Código</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`}  
                                        type="text" 
                                        id="txtCodigo"
                                        disabled={!showEdicion.nuevo}
                                        placeholder=""
                                        value={ruta.code}
                                        onChange={(evt) => {
                                            setRuta({ ...ruta, code: evt.target.value })
                                        }}
                                    />
                                    {
                                        showEdicion.nuevo &&
                                        <p className="label-admin-recomendacion">* Ingresar solo letras minúsculas y guiones medio (-), este campo es usado para redireccionar al servicio en el sitio web.</p> 
                                    }
                                </Col>  
                                <Col lg={4}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Nave</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`}  
                                        type="text" 
                                        id="txtCodigo"
                                        placeholder="PATHAGON | YAGHAN | CRUZ AUSTRALIS"
                                        value={ruta.nave}
                                        onChange={(evt) => {
                                            setRuta({ ...ruta, nave: evt.target.value })
                                        }}
                                    />
                                </Col> 
                                <Col lg={4}>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Background del servicio:</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`} 
                                        type="file" 
                                        id="txtFileL"
                                        placeholder="Sube la imagen background."
                                        accept="image/jpeg"
                                        onChange={(evt) => {
                                            if (evt.target.files.length > 0) {

                                                setShowLoading(true);

                                                let formData = new FormData();

                                                formData.append("pdf", evt.target.files[0]);
                                                
                                                fetch('https://recursos.tabsa.cl/api/file/save', {
                                                    method: "POST",
                                                    body: formData
                                                })
                                                .then(response => {
                                                    if (response.ok) {
                                                        response.json().then(data => {
                                                            setRuta({ ...ruta, imgBack: data.url })
                                                            setShowLoading(false);
                                                        })
                                                    }
                                                    else {
                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                        setShowLoading(false);
                                                    }
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })

                                            }
                                        }}
                                    />
                                    <p className="label-admin-recomendacion">* Resolución recomendada: 1920x737</p>   
                                </Col>
                                <Col lg={12}>
                                {
                                    ruta.imgBack &&
                                    <img className="img-services-s" alt={ruta.code} src={ruta.imgBack} />
                                }
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Barra de navegación</Form.Label>
                                    <br  />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={4}>
                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Texto (ES)</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`}  
                                        type="text" 
                                        id="txtCodigo"
                                        placeholder=""
                                        value={ruta.navbar.es}
                                        onChange={(evt) => {
                                            setRuta({ ...ruta, navbar: { es: evt.target.value, en: ruta.navbar.en } });
                                        }}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Texto (EN)</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`}  
                                        type="text" 
                                        id="txtCodigo"
                                        placeholder=""
                                        value={ruta.navbar.en}
                                        onChange={(evt) => {
                                            setRuta({ ...ruta, navbar: { es: ruta.navbar.es, en: evt.target.value } });
                                        }}
                                    />
                                </Col>
                                <Col lg={4}>

                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Google maps</Form.Label>
                                    <br  />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={6}>
                                    <Row>
                                        <Col lg={2}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Zoom</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="number" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={ruta.zoom}
                                                onChange={(evt) => {
                                                    setShowMap(false);
                                                    setRuta({ ...ruta, zoom: parseFloat(evt.target.value) });
                                                    setTimeout(() => {setShowMap(true);}, 200);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Latitud</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="number" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={newLatitud}
                                                onChange={(evt) => {
                                                    setNewLatitud(parseFloat(evt.target.value));
                                                }}
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Longitud</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="number" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={newLongitud}
                                                onChange={(evt) => {
                                                    setNewLongitud(parseFloat(evt.target.value));
                                                }}
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <Button className="btn-admin-add" onClick={() => {
                                                setRuta({ ...ruta, coords: ruta.coords.concat({ lat: newLatitud, lng: newLongitud }) });
                                            }}>Agregar</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="div-coords-admin">
                                                {
                                                    ruta.coords && ruta.coords.map((coord, idx) => (
                                                        <Row key={`row-coords-map-${idx}`}>
                                                            <Col lg={4}>
                                                                <p className="label-admin">{coord.lat}</p>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <p className="label-admin">{coord.lng}</p>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <Button className="btn-admin-remove" onClick={() => {
                                                                    let newCoords = ruta.coords;
                                                                    newCoords.splice(idx, 1);
                                                                    setRuta({ ...ruta, coords: newCoords });
                                                                }}>Eliminar</Button>
                                                            </Col>
                                                        </Row>
                                                    ))
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Mapa</Form.Label>
                                    {
                                        ruta.coords && ruta.coords.length > 1 && ruta.zoom && showMap &&
                                        <GoogleMap coords={ruta.coords} mapZoom={ruta.zoom} />
                                    }
                                </Col>
                            </Row>
                            <br />
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Card</Form.Label>
                                    <br  />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={8}>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`} 
                                                type="file" 
                                                id="txtFileL"
                                                placeholder="Sube la imagen background."
                                                accept="image/jpeg"
                                                onChange={(evt) => {
                                                    if (evt.target.files.length > 0) {

                                                        setShowLoading(true);

                                                        let formData = new FormData();

                                                        formData.append("pdf", evt.target.files[0]);
                                                        
                                                        fetch('https://recursos.tabsa.cl/api/file/save', {
                                                            method: "POST",
                                                            body: formData
                                                        })
                                                        .then(response => {
                                                            if (response.ok) {
                                                                response.json().then(data => {
                                                                    setRuta({ ...ruta, cardImg: data.url });
                                                                    setShowLoading(false);
                                                                })
                                                            }
                                                            else {
                                                                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                setShowLoading(false);
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                        })

                                                    }
                                                }}
                                            />
                                            <p className="label-admin-recomendacion">* Resolución recomendada: 686x513</p>   
                                        </Col> 
                                        <Col lg={6}>
                                        </Col> 
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Titulo (ES)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={ruta.cardTitulo.es}
                                                onChange={(evt) => {
                                                    setRuta({ ...ruta, cardTitulo: { es: evt.target.value, en: ruta.cardTitulo.en } });
                                                }}
                                            />
                                        </Col>  
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Titulo (EN)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={ruta.cardTitulo.en}
                                                onChange={(evt) => {
                                                    setRuta({ ...ruta, cardTitulo: { es: ruta.cardTitulo.es, en: evt.target.value } });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Descripción (ES)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={ruta.cardDetalle.es}
                                                onChange={(evt) => {
                                                    setRuta({ ...ruta, cardDetalle: { es: evt.target.value, en: ruta.cardDetalle.en } });
                                                }}
                                            />
                                        </Col>  
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Descripción (EN)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={ruta.cardDetalle.en}
                                                onChange={(evt) => {
                                                    setRuta({ ...ruta, cardDetalle: { es: ruta.cardDetalle.es, en: evt.target.value } });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>  
                                <Col className="col-card-servicio" lg={4}>
                                    <Card className="card-servicio">
                                        <Row>
                                            <Col lg={12}>
                                                {
                                                    ruta.cardImg &&
                                                    <img className="img-servicio" alt={ruta.cardTitulo.es} src={ruta.cardImg} />
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="div-detalle-servicio" lg={12}>
                                                {
                                                    ruta.cardTitulo && ruta.cardTitulo.es &&
                                                    <p className="p-nombre-servicio"><span className="p-b">{ruta.cardTitulo.es}</span></p>
                                                }
                                                {
                                                    ruta.cardDetalle && ruta.cardDetalle.es &&
                                                    <p className="p-detalle-servicio">{ruta.cardDetalle.es}</p>
                                                }
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={2}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Textos</Form.Label>
                                    <br  />
                                    <Button className="btn-admin-editar" onClick={() => {
                                        setRuta({ ...ruta, contenido: ruta.contenido.concat({ es: "", en: "" }) });
                                    }}>Agregar</Button>
                                    <br  />
                                    <br  />
                                </Col>
                                <Col lg={10}>
                                </Col>
                            </Row>
                            {
                                ruta.contenido.map((c, idx) => (
                                    <Row key={`row-texts-${idx}`} className="justify-content-center">
                                        <Col lg={5}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Español (ES)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={ruta.contenido[idx].es}
                                                onChange={(evt) => {

                                                    let newContenidos = ruta.contenido;
                                                    newContenidos[idx].es = evt.target.value
                                                    setRuta({ ...ruta, contenido: newContenidos });
                                                }}
                                            />
                                        </Col>
                                        <Col lg={5}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Ingles (EN)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={ruta.contenido[idx].en}
                                                onChange={(evt) => {

                                                    let newContenidos = ruta.contenido;
                                                    newContenidos[idx].en = evt.target.value
                                                    setRuta({ ...ruta, contenido: newContenidos });
                                                }}
                                            />
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Acciones</Form.Label>
                                            <br />
                                            <Button className="btn-admin-eliminar" onClick={() => {
                                                let newContenidos = ruta.contenido;
                                                newContenidos.splice(idx, 1);
                                                setRuta({ ...ruta, contenido: newContenidos });
                                            }}>Quitar</Button>
                                        </Col>
                                    </Row>
                                ))
                            }
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={4}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Tarifas</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`} 
                                        type="file" 
                                        id="txtFileL"
                                        placeholder="Sube el pdf de las tarifas."
                                        accept="application/pdf"
                                        onChange={(evt) => {
                                            if (evt.target.files.length > 0) {

                                                setShowLoading(true);

                                                let formData = new FormData();

                                                formData.append("pdf", evt.target.files[0]);
                                                
                                                fetch('https://recursos.tabsa.cl/api/file/save', {
                                                    method: "POST",
                                                    body: formData,
                                                })
                                                .then(response => {
                                                    if (response.ok) {
                                                        response.json().then(data => {
                                                            setRuta({ ...ruta, tarifas: data.url });
                                                            setShowLoading(false);
                                                        }) 
                                                    }
                                                    else {
                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                        setShowLoading(false);
                                                    }
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                            }
                                        }}
                                    />
                                    {
                                        ruta.tarifas &&
                                        <Button className="btn-admin-remove" onClick={() => {
                                            setRuta({ ...ruta, tarifas: null });
                                        }}>Eliminar tarifas</Button>
                                    }
                                </Col>
                                <Col lg={8}>
                                    {
                                        ruta.tarifas &&
                                        <div className="div-pdf-body-admin">
                                            <Container>
                                                <Row className="justify-content-center">
                                                    <Col lg={12}>
                                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                                            <div className="div-pdf-viewer-admin">
                                                                <Viewer fileUrl={ruta.tarifas} />
                                                            </div>
                                                        </Worker>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={4}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Términos y Condiciones</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`} 
                                        type="file" 
                                        id="txtFileL"
                                        placeholder="Sube el pdf de los términos y condiciones."
                                        accept="application/pdf"
                                        onChange={(evt) => {
                                            if (evt.target.files.length > 0) {

                                                setShowLoading(true);

                                                let formData = new FormData();

                                                formData.append("pdf", evt.target.files[0]);
                                                
                                                fetch('https://recursos.tabsa.cl/api/file/save', {
                                                    method: "POST",
                                                    body: formData
                                                })
                                                .then(response => {
                                                    if (response.ok) {
                                                        response.json().then(data => {
                                                            setRuta({ ...ruta, terminosCondiciones: data.url });
                                                            setShowLoading(false);
                                                        })
                                                    }
                                                    else {
                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                        setShowLoading(false);
                                                    }
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                            }
                                        }}
                                    />
                                    {
                                        ruta.terminosCondiciones &&
                                        <Button className="btn-admin-remove" onClick={() => {
                                            setRuta({ ...ruta, terminosCondiciones: null });
                                        }}>Eliminar términos y condiciones</Button>
                                    }
                                </Col>
                                <Col lg={8}>
                                    {
                                        ruta.terminosCondiciones &&
                                        <div className="div-pdf-body-admin">
                                            <Container>
                                                <Row className="justify-content-center">
                                                    <Col lg={12}>
                                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                                            <div className="div-pdf-viewer-admin">
                                                                <Viewer fileUrl={ruta.terminosCondiciones} />
                                                            </div>
                                                        </Worker>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={4}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Manual de operaciones</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`} 
                                        type="file" 
                                        id="txtFileL"
                                        placeholder="Sube el pdf del manual de operaciones."
                                        accept="application/pdf"
                                        onChange={(evt) => {
                                            if (evt.target.files.length > 0) {

                                                setShowLoading(true);

                                                let formData = new FormData();

                                                formData.append("pdf", evt.target.files[0]);
                                                
                                                fetch('https://recursos.tabsa.cl/api/file/save', {
                                                    method: "POST",
                                                    body: formData
                                                })
                                                .then(response => {
                                                    if (response.ok) {
                                                        response.json().then(data => {
                                                            setRuta({ ...ruta, manualOperaciones: data.url });
                                                            setShowLoading(false);
                                                        })
                                                    }
                                                    else {
                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                        setShowLoading(false);
                                                    }
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })
                                            }
                                        }}
                                    />
                                    {
                                        ruta.manualOperaciones &&
                                        <Button className="btn-admin-remove" onClick={() => {
                                            setRuta({ ...ruta, manualOperaciones: null });
                                        }}>Eliminar manual de operaciones</Button>
                                    }
                                </Col>
                                <Col lg={8}>
                                    {
                                        ruta.manualOperaciones &&
                                        <div className="div-pdf-body-admin">
                                            <Container>
                                                <Row className="justify-content-center">
                                                    <Col lg={12}>
                                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                                            <div className="div-pdf-viewer-admin">
                                                                <Viewer fileUrl={ruta.manualOperaciones} />
                                                            </div>
                                                        </Worker>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Ferry</Form.Label>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={6}>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Servicio (ES)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={newServicio.servicio.es}
                                                onChange={(evt) => {
                                                    setNewServicio({ ...newServicio, servicio: { es: evt.target.value, en: newServicio.servicio.en }  })
                                                }}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Servicio (EN)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={newServicio.servicio.en}
                                                onChange={(evt) => {
                                                    setNewServicio({ ...newServicio, servicio: { es: newServicio.servicio.es, en: evt.target.value }  })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Descripción (ES)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={newServicio.descripcion.es}
                                                onChange={(evt) => {
                                                    setNewServicio({ ...newServicio, descripcion: { es: evt.target.value, en: newServicio.descripcion.en }  })
                                                }}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Descripción (EN)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={newServicio.descripcion.en}
                                                onChange={(evt) => {
                                                    setNewServicio({ ...newServicio, descripcion: { es: newServicio.descripcion.es, en: evt.target.value }  })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={10}>
                                        </Col>
                                        <Col lg={2}>
                                            <Button className="btn-admin-add" onClick={() => {

                                                let newServiciosAdd = (ruta.ferry && ruta.ferry.servicios) ? ruta.ferry.servicios.concat(newServicio) : [newServicio]

                                                setRuta({ ...ruta, ferry: { ...ruta.ferry, servicios: newServiciosAdd } });
                                            }}>Agregar</Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    <table className="table-admin-serv">
                                        <tbody>
                                        {
                                            ruta.ferry && ruta.ferry.servicios && ruta.ferry.servicios.map((s, idx) => (
                                                <tr key={`key-serv-${idx}`}>
                                                    <td className="td-body">{ s.servicio.es }</td>
                                                    <td className="td-body">{ s.descripcion.es }</td>
                                                    <td>
                                                        <Button className="btn-admin-eliminar" onClick={() => {
                                                            let newFerry = ruta.ferry;
                                                            newFerry.servicios.splice(idx, 1);
                                                            setRuta({ ...ruta, ferry: newFerry });
                                                        }}>Quitar</Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }     
                                    </tbody>
                                </table>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={6}>
                                    <Row>
                                        <Col>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen superior</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`} 
                                                type="file" 
                                                id="txtFileL"
                                                placeholder="Sube la imagen background."
                                                accept="image/jpeg"
                                                onChange={(evt) => {
                                                    if (evt.target.files.length > 0) {

                                                        setShowLoading(true);

                                                        let formData = new FormData();

                                                        formData.append("pdf", evt.target.files[0]);
                                                        
                                                        fetch('https://recursos.tabsa.cl/api/file/save', {
                                                            method: "POST",
                                                            body: formData
                                                        })
                                                        .then(response => {
                                                            if (response.ok) {
                                                                response.json().then(data => {
                                                                    setRuta({ ...ruta, ferry: { ...ruta.ferry, imgT: data.url } });
                                                                    setShowLoading(false);
                                                                })
                                                            }
                                                            else {
                                                                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                setShowLoading(false);
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                        })

                                                    }
                                                }}
                                            />
                                            <p className="label-admin-recomendacion">* Resolución recomendada: 726x521</p> 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen inferior izquierda</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`} 
                                                type="file" 
                                                id="txtFileL"
                                                placeholder="Sube la imagen background."
                                                accept="image/jpeg"
                                                onChange={(evt) => {
                                                    if (evt.target.files.length > 0) {

                                                        setShowLoading(true);

                                                        let formData = new FormData();

                                                        formData.append("pdf", evt.target.files[0]);
                                                        
                                                        fetch('https://recursos.tabsa.cl/api/file/save', {
                                                            method: "POST",
                                                            body: formData
                                                        })
                                                        .then(response => {
                                                            if (response.ok) {
                                                                response.json().then(data => {
                                                                    setRuta({ ...ruta, ferry: { ...ruta.ferry, imgBL: data.url } });
                                                                    setShowLoading(false);
                                                                })
                                                            }
                                                            else {
                                                                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                setShowLoading(false);
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                        })

                                                    }
                                                }}
                                            />
                                            <p className="label-admin-recomendacion">* Resolución recomendada: 471x399</p> 
                                        </Col>
                                        <Col lg={6}>
                                            <Row>
                                                <Col>
                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen centro derecha</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-admin`} 
                                                        type="file" 
                                                        id="txtFileL"
                                                        placeholder="Sube la imagen background."
                                                        accept="image/jpeg"
                                                        onChange={(evt) => {
                                                            if (evt.target.files.length > 0) {

                                                                setShowLoading(true);

                                                                let formData = new FormData();

                                                                formData.append("pdf", evt.target.files[0]);
                                                                
                                                                fetch('https://recursos.tabsa.cl/api/file/save', {
                                                                    method: "POST",
                                                                    body: formData
                                                                })
                                                                .then(response => {
                                                                    if (response.ok) {
                                                                        response.json().then(data => {
                                                                            setRuta({ ...ruta, ferry: { ...ruta.ferry, imgCR: data.url } });
                                                                            setShowLoading(false);
                                                                        })
                                                                    }
                                                                    else {
                                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                        setShowLoading(false);
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                })

                                                            }
                                                        }}
                                                    />
                                                    <p className="label-admin-recomendacion">* Resolución recomendada: 242x232</p> 
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen inferior derecha</Form.Label>
                                                    <Form.Control 
                                                        className={`form-field-admin`} 
                                                        type="file" 
                                                        id="txtFileL"
                                                        placeholder="Sube la imagen background."
                                                        accept="image/jpeg"
                                                        onChange={(evt) => {
                                                            if (evt.target.files.length > 0) {

                                                                setShowLoading(true);

                                                                let formData = new FormData();

                                                                formData.append("pdf", evt.target.files[0]);
                                                                
                                                                fetch('https://recursos.tabsa.cl/api/file/save', {
                                                                    method: "POST",
                                                                    body: formData
                                                                })
                                                                .then(response => {
                                                                    if (response.ok) {
                                                                        response.json().then(data => {
                                                                            setRuta({ ...ruta, ferry: { ...ruta.ferry, imgBR: data.url } });
                                                                            setShowLoading(false);
                                                                        })
                                                                    }
                                                                    else {
                                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                        setShowLoading(false);
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    console.log(error)
                                                                })

                                                            }
                                                        }}
                                                    />
                                                    <p className="label-admin-recomendacion">* Resolución recomendada: 242x154</p> 
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    <Row className="no-margin">
                                        <Col className="no-margin" lg={12}>
                                        {
                                            ruta.ferry && ruta.ferry.imgT &&
                                            <img src={ruta.ferry.imgT} className="ferry-img-t" alt="ferry" />
                                        }
                                        </Col>
                                    </Row>
                                    <Row className="no-margin">
                                        <Col className="no-margin" lg={8}>
                                        {
                                            ruta.ferry && ruta.ferry.imgBL &&
                                            <img src={ruta.ferry.imgBL} className="ferry-img-b-l" alt="ferry" />
                                        }
                                        </Col>
                                        <Col className="no-margin" lg={4}>
                                            <Row className="no-margin">
                                                <Col className="no-margin" lg={12}>
                                                {
                                                    ruta.ferry && ruta.ferry.imgCR &&
                                                    <img src={ruta.ferry.imgCR} className="ferry-img-c-r" alt="ferry" />
                                                }
                                                </Col>
                                            </Row>
                                            <Row className="no-margin">
                                                <Col className="no-margin" lg={12}>
                                                {
                                                    ruta.ferry && ruta.ferry.imgBR &&
                                                    <img src={ruta.ferry.imgBR} className="ferry-img-b-r" alt="ferry" />
                                                }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={2}>
                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Detalles del servicio</Form.Label>
                                    <br  />
                                    <Button className="btn-admin-editar" onClick={() => {
                                        let newDetalles = (ruta.ferry && ruta.ferry.detalles ? ruta.ferry.detalles : []).concat({ es: "", en: "" })
                                        setRuta({ ...ruta, ferry: { ...ruta.ferry, detalles: newDetalles } });
                                    }}>Agregar</Button>
                                    <br  />
                                    <br  />
                                </Col>
                                <Col lg={10}>
                                </Col>
                            </Row>
                            
                            {
                                ruta.ferry && ruta.ferry.detalles && ruta.ferry.detalles.map((c, idx) => (
                                    <Row key={`row-texts-${idx}`} className="justify-content-center">
                                        <Col lg={5}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Español (ES)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={ruta.ferry.detalles[idx].es}
                                                onChange={(evt) => {

                                                    let newDetalles = ruta.ferry.detalles;
                                                    newDetalles[idx].es = evt.target.value
                                                    setRuta({ ...ruta, ferry: { ...ruta.ferry, detalles: newDetalles } });
                                                }}
                                            />
                                        </Col>
                                        <Col lg={5}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Ingles (EN)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={ruta.ferry.detalles[idx].en}
                                                onChange={(evt) => {

                                                    let newDetalles = ruta.ferry.detalles;
                                                    newDetalles[idx].en = evt.target.value
                                                    setRuta({ ...ruta, ferry: { ...ruta.ferry, detalles: newDetalles } });
                                                }}
                                            />
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Acciones</Form.Label>
                                            <br />
                                            <Button className="btn-admin-eliminar" onClick={() => {
                                                let newDetalles = ruta.ferry.detalles;
                                                newDetalles.splice(idx, 1);
                                                setRuta({ ...ruta, ferry: { ...ruta.ferry, detalles: newDetalles } });
                                            }}>Quitar</Button>
                                        </Col>
                                    </Row>
                                ))
                            }
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Horarios de atención</Form.Label>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Tabs
                                        defaultActiveKey="tabla"
                                        id="uncontrolled-tab-example"
                                        className="mb-3">
                                        <Tab eventKey="tabla" title="Tablas">
                                            <Row>
                                                <Col>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = newAtencionTabla.tabla;
                                                        detalles.push({ titulo: { es: "", en: "" }, span: 4 });
                                                        setNewAtencionTabla({ ...newAtencionTabla, tabla: detalles })
                                                    }}>Agregar Título</Button>
                                                </Col>
                                                <Col>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = newAtencionTabla.tabla;
                                                        detalles.push({ subtitulos: [{ es: "", en: "" }], span: 2 });
                                                        setNewAtencionTabla({ ...newAtencionTabla, tabla: detalles })
                                                    }}>Agregar Subtítulo</Button>
                                                </Col>
                                                <Col>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = newAtencionTabla.tabla;
                                                        detalles.push({ subtitulos: [{ es: "", en: "" }, { es: "", en: "" }], span: 2 });
                                                        setNewAtencionTabla({ ...newAtencionTabla, tabla: detalles })
                                                    }}>Agregar Subtítulo (doble)</Button>
                                                </Col>
                                                <Col>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = newAtencionTabla.tabla;
                                                        let maxSpan = Math.max(...detalles.map(d => d.span || 1))

                                                        let newBody = []
                                                        for (let i = 0; i < maxSpan; i++) {
                                                            newBody.push([{ es: "", en: "" }]);
                                                        }
                                                        
                                                        detalles.push({ body: newBody, span: maxSpan });
                                                        setNewAtencionTabla({ ...newAtencionTabla, tabla: detalles })
                                                    }}>Agregar Contenido</Button>
                                                </Col>
                                                <Col>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = newAtencionTabla.tabla;
                                                        detalles.push({ footer: [{ es: "", en: "" }], span: 4 });
                                                        setNewAtencionTabla({ ...newAtencionTabla, tabla: detalles })
                                                    }}>Agregar Footer</Button>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col lg={12}>
                                                    <br />
                                                    <br />
                                                {
                                                    newAtencionTabla && newAtencionTabla.tabla &&
                                                    newAtencionTabla.tabla.map((t, tIdx) => {
                                                        if (t.titulo) {
                                                            return (
                                                            <Row>
                                                                <Col lg={4}>
                                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Título (ES)</Form.Label>
                                                                    <Form.Control 
                                                                        className={`form-field-admin`}  
                                                                        type="text" 
                                                                        id="txtCodigo"
                                                                        placeholder=""
                                                                        value={newAtencionTabla.tabla[tIdx].titulo.es}
                                                                        onChange={(evt) => {

                                                                            let newDetalles = newAtencionTabla.tabla;
                                                                            newDetalles[tIdx].titulo.es = evt.target.value
                                                                            setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col lg={4}>
                                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Título (EN)</Form.Label>
                                                                    <Form.Control 
                                                                        className={`form-field-admin`}  
                                                                        type="text" 
                                                                        id="txtCodigo"
                                                                        placeholder=""
                                                                        value={newAtencionTabla.tabla[tIdx].titulo.en}
                                                                        onChange={(evt) => {

                                                                            let newDetalles = newAtencionTabla.tabla;
                                                                            newDetalles[tIdx].titulo.en = evt.target.value
                                                                            setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col lg={1}>
                                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Span</Form.Label>
                                                                    <Form.Select 
                                                                        className={`form-field-admin`} 
                                                                        value={newAtencionTabla.tabla[tIdx].span}
                                                                        onChange={(evt) => {
                                                                            let newDetalles = newAtencionTabla.tabla;
                                                                            newDetalles[tIdx].span = parseInt(evt.target.value)
                                                                            setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                        }}
                                                                    >   
                                                                        <option>2</option>
                                                                        <option>4</option>
                                                                    </Form.Select>
                                                                </Col>
                                                                <Col lg={2}>
                                                                    <br />
                                                                    <Button className="btn-admin-eliminar" onClick={() => {
                                                                        let dataTabla = newAtencionTabla.tabla;
                                                                        dataTabla.splice(tIdx, 1);
                                                                        setNewAtencionTabla({ ...newAtencionTabla, tabla: dataTabla });
                                                                    }}>Quitar</Button>
                                                                </Col>
                                                                <hr />
                                                            </Row>
                                                            )
                                                        }
                                                        else if (t.subtitulos) {
                                                            return (
                                                            <Row>
                                                                <Col lg={2}>
                                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Subtítulo (ES)</Form.Label>
                                                                    <Form.Control 
                                                                        className={`form-field-admin`}  
                                                                        type="text" 
                                                                        id="txtCodigo"
                                                                        placeholder=""
                                                                        value={newAtencionTabla.tabla[tIdx].subtitulos[0].es}
                                                                        onChange={(evt) => {

                                                                            let newDetalles = newAtencionTabla.tabla;
                                                                            newDetalles[tIdx].subtitulos[0].es = evt.target.value
                                                                            setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col lg={2}>
                                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Subtítulo (EN)</Form.Label>
                                                                    <Form.Control 
                                                                        className={`form-field-admin`}  
                                                                        type="text" 
                                                                        id="txtCodigo"
                                                                        placeholder=""
                                                                        value={newAtencionTabla.tabla[tIdx].subtitulos[0].en}
                                                                        onChange={(evt) => {

                                                                            let newDetalles = newAtencionTabla.tabla;
                                                                            newDetalles[tIdx].subtitulos[0].en = evt.target.value
                                                                            setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col lg={2}>
                                                                    {
                                                                        newAtencionTabla.tabla[tIdx].subtitulos.length > 1 &&
                                                                        <>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Subtítulo (ES)</Form.Label>
                                                                            <Form.Control 
                                                                                className={`form-field-admin`}  
                                                                                type="text" 
                                                                                id="txtCodigo"
                                                                                placeholder=""
                                                                                value={newAtencionTabla.tabla[tIdx].subtitulos[1].es}
                                                                                onChange={(evt) => {

                                                                                    let newDetalles = newAtencionTabla.tabla;
                                                                                    newDetalles[tIdx].subtitulos[1].es = evt.target.value
                                                                                    setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                }}
                                                                            />
                                                                        </>
                                                                    }
                                                                </Col>
                                                                <Col lg={2}>
                                                                    {
                                                                        newAtencionTabla.tabla[tIdx].subtitulos.length > 1 &&
                                                                        <>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Subtítulo (EN)</Form.Label>
                                                                            <Form.Control 
                                                                                className={`form-field-admin`}  
                                                                                type="text" 
                                                                                id="txtCodigo"
                                                                                placeholder=""
                                                                                value={newAtencionTabla.tabla[tIdx].subtitulos[1].en}
                                                                                onChange={(evt) => {

                                                                                    let newDetalles = newAtencionTabla.tabla;
                                                                                    newDetalles[tIdx].subtitulos[1].en = evt.target.value
                                                                                    setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                }}
                                                                            />
                                                                        </>
                                                                    }
                                                                </Col>
                                                                <Col lg={1}>
                                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Span</Form.Label>
                                                                    <Form.Select 
                                                                        className={`form-field-admin`} 
                                                                        value={newAtencionTabla.tabla[tIdx].span}
                                                                        onChange={(evt) => {
                                                                            let newDetalles = newAtencionTabla.tabla;
                                                                            newDetalles[tIdx].span = parseInt(evt.target.value)
                                                                            setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                        }}
                                                                    >   
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>4</option>
                                                                    </Form.Select>
                                                                </Col>
                                                                <Col lg={2}>
                                                                    <br />
                                                                    <Button className="btn-admin-eliminar" onClick={() => {
                                                                        let dataTabla = newAtencionTabla.tabla;
                                                                        dataTabla.splice(tIdx, 1);
                                                                        setNewAtencionTabla({ ...newAtencionTabla, tabla: dataTabla });
                                                                    }}>Quitar</Button>
                                                                </Col>
                                                                <hr />
                                                            </Row>
                                                            )
                                                        }
                                                        else if (t.body) {
                                                            return (
                                                            <Row>
                                                                <Col lg={2}>
                                                                    {
                                                                        newAtencionTabla.tabla[tIdx].body.length > 0 &&
                                                                        <>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Contenido (ES)
                                                                                <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                                                    let newDetalles = newAtencionTabla.tabla;
                                                                                    newDetalles[tIdx].body[0].push({ es: "", en: "" })
                                                                                    setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                }}> + </Button>
                                                                            </Form.Label>
                                                                            {
                                                                                newAtencionTabla.tabla[tIdx].body[0].length > 0 && 
                                                                                newAtencionTabla.tabla[tIdx].body[0].map((bdy, bdyIdx) => {
                                                                                    return (
                                                                                        <Form.Control 
                                                                                            className={`form-field-admin`}  
                                                                                            type="text" 
                                                                                            id="txtCodigo"
                                                                                            placeholder=""
                                                                                            value={bdy.es}
                                                                                            onChange={(evt) => {
            
                                                                                                let newDetalles = newAtencionTabla.tabla;
                                                                                                newDetalles[tIdx].body[0][bdyIdx].es = evt.target.value
                                                                                                setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                            <br />
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Contenido (EN)</Form.Label>
                                                                            {
                                                                                newAtencionTabla.tabla[tIdx].body[0].length > 0 && 
                                                                                newAtencionTabla.tabla[tIdx].body[0].map((bdy, bdyIdx) => {
                                                                                    return (
                                                                                        <Form.Control 
                                                                                            className={`form-field-admin`}  
                                                                                            type="text" 
                                                                                            id="txtCodigo"
                                                                                            placeholder=""
                                                                                            value={bdy.en}
                                                                                            onChange={(evt) => {
            
                                                                                                let newDetalles = newAtencionTabla.tabla;
                                                                                                newDetalles[tIdx].body[0][bdyIdx].en = evt.target.value
                                                                                                setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    }
                                                                </Col>
                                                                <Col lg={2}>
                                                                    {
                                                                        newAtencionTabla.tabla[tIdx].body.length > 1 &&
                                                                        <>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Contenido (ES)
                                                                                <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                                                    let newDetalles = newAtencionTabla.tabla;
                                                                                    newDetalles[tIdx].body[1].push({ es: "", en: "" })
                                                                                    setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                }}> + </Button>
                                                                            </Form.Label>
                                                                            {
                                                                                newAtencionTabla.tabla[tIdx].body[1].length > 0 && 
                                                                                newAtencionTabla.tabla[tIdx].body[1].map((bdy, bdyIdx) => {
                                                                                    return (
                                                                                        <Form.Control 
                                                                                            className={`form-field-admin`}  
                                                                                            type="text" 
                                                                                            id="txtCodigo"
                                                                                            placeholder=""
                                                                                            value={bdy.es}
                                                                                            onChange={(evt) => {
            
                                                                                                let newDetalles = newAtencionTabla.tabla;
                                                                                                newDetalles[tIdx].body[1][bdyIdx].es = evt.target.value
                                                                                                setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                            <br />
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Contenido (EN)</Form.Label>
                                                                            {
                                                                                newAtencionTabla.tabla[tIdx].body[1].length > 0 && 
                                                                                newAtencionTabla.tabla[tIdx].body[1].map((bdy, bdyIdx) => {
                                                                                    return (
                                                                                        <Form.Control 
                                                                                            className={`form-field-admin`}  
                                                                                            type="text" 
                                                                                            id="txtCodigo"
                                                                                            placeholder=""
                                                                                            value={bdy.en}
                                                                                            onChange={(evt) => {
            
                                                                                                let newDetalles = newAtencionTabla.tabla;
                                                                                                newDetalles[tIdx].body[1][bdyIdx].en = evt.target.value
                                                                                                setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    }
                                                                </Col>
                                                                <Col lg={2}>
                                                                    {
                                                                        newAtencionTabla.tabla[tIdx].body.length > 2 &&
                                                                        <>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Contenido (ES)
                                                                                <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                                                    let newDetalles = newAtencionTabla.tabla;
                                                                                    newDetalles[tIdx].body[2].push({ es: "", en: "" })
                                                                                    setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                }}> + </Button>
                                                                            </Form.Label>
                                                                            {
                                                                                newAtencionTabla.tabla[tIdx].body[2].length > 0 && 
                                                                                newAtencionTabla.tabla[tIdx].body[2].map((bdy, bdyIdx) => {
                                                                                    return (
                                                                                        <Form.Control 
                                                                                            className={`form-field-admin`}  
                                                                                            type="text" 
                                                                                            id="txtCodigo"
                                                                                            placeholder=""
                                                                                            value={bdy.es}
                                                                                            onChange={(evt) => {
            
                                                                                                let newDetalles = newAtencionTabla.tabla;
                                                                                                newDetalles[tIdx].body[2][bdyIdx].es = evt.target.value
                                                                                                setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                            <br />
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Contenido (EN)</Form.Label>
                                                                            {
                                                                                newAtencionTabla.tabla[tIdx].body[2].length > 0 && 
                                                                                newAtencionTabla.tabla[tIdx].body[2].map((bdy, bdyIdx) => {
                                                                                    return (
                                                                                        <Form.Control 
                                                                                            className={`form-field-admin`}  
                                                                                            type="text" 
                                                                                            id="txtCodigo"
                                                                                            placeholder=""
                                                                                            value={bdy.en}
                                                                                            onChange={(evt) => {
            
                                                                                                let newDetalles = newAtencionTabla.tabla;
                                                                                                newDetalles[tIdx].body[2][bdyIdx].en = evt.target.value
                                                                                                setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    }
                                                                </Col>
                                                                <Col lg={2}>
                                                                    {
                                                                        newAtencionTabla.tabla[tIdx].body.length > 3 &&
                                                                        <>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Contenido (ES)
                                                                                <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                                                    let newDetalles = newAtencionTabla.tabla;
                                                                                    newDetalles[tIdx].body[3].push({ es: "", en: "" })
                                                                                    setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                }}> + </Button>
                                                                            </Form.Label>
                                                                            {
                                                                                newAtencionTabla.tabla[tIdx].body[3].length > 0 && 
                                                                                newAtencionTabla.tabla[tIdx].body[3].map((bdy, bdyIdx) => {
                                                                                    return (
                                                                                        <Form.Control 
                                                                                            className={`form-field-admin`}  
                                                                                            type="text" 
                                                                                            id="txtCodigo"
                                                                                            placeholder=""
                                                                                            value={bdy.es}
                                                                                            onChange={(evt) => {
            
                                                                                                let newDetalles = newAtencionTabla.tabla;
                                                                                                newDetalles[tIdx].body[3][bdyIdx].es = evt.target.value
                                                                                                setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                            <br />
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Contenido (EN)</Form.Label>
                                                                            {
                                                                                newAtencionTabla.tabla[tIdx].body[3].length > 0 && 
                                                                                newAtencionTabla.tabla[tIdx].body[3].map((bdy, bdyIdx) => {
                                                                                    return (
                                                                                        <Form.Control 
                                                                                            className={`form-field-admin`}  
                                                                                            type="text" 
                                                                                            id="txtCodigo"
                                                                                            placeholder=""
                                                                                            value={bdy.en}
                                                                                            onChange={(evt) => {
            
                                                                                                let newDetalles = newAtencionTabla.tabla;
                                                                                                newDetalles[tIdx].body[3][bdyIdx].en = evt.target.value
                                                                                                setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    }
                                                                </Col>
                                                                <Col lg={1}>
                                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Columnas</Form.Label>
                                                                    <Form.Select 
                                                                        className={`form-field-admin`} 
                                                                        value={newAtencionTabla.tabla[tIdx].body.length}
                                                                        onChange={(evt) => {
                                                                            let newDetalles = newAtencionTabla.tabla;

                                                                            let newBody = []
                                                                            for (let i = 0; i < parseInt(evt.target.value); i++) {
                                                                                newBody.push([{ es: "", en: "" }]);
                                                                            }

                                                                            newDetalles[tIdx].body = newBody
                                                                            setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                        }}
                                                                    >   
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>4</option>
                                                                    </Form.Select>
                                                                </Col>
                                                                <Col lg={2}>
                                                                    <br />
                                                                    <Button className="btn-admin-eliminar" onClick={() => {
                                                                        let dataTabla = newAtencionTabla.tabla;
                                                                        dataTabla.splice(tIdx, 1);
                                                                        setNewAtencionTabla({ ...newAtencionTabla, tabla: dataTabla });
                                                                    }}>Quitar</Button>
                                                                </Col>
                                                                <hr />
                                                            </Row>
                                                            )
                                                        }
                                                        else if (t.footer) {
                                                            return (
                                                                <Row>
                                                                    <Col lg={4}>
                                                                        {
                                                                            newAtencionTabla.tabla[tIdx].footer && 
                                                                            newAtencionTabla.tabla[tIdx].footer.map((foot, fIdx) => {
                                                                                return (
                                                                                    <>
                                                                                        <Form.Label className="label-admin" htmlFor="txtCodigo">Footer (ES)</Form.Label>
                                                                                        <Form.Control 
                                                                                            className={`form-field-admin`}  
                                                                                            type="text" 
                                                                                            id="txtCodigo"
                                                                                            placeholder=""
                                                                                            value={newAtencionTabla.tabla[tIdx].footer[fIdx].es}
                                                                                            onChange={(evt) => {
                    
                                                                                                let newDetalles = newAtencionTabla.tabla;
                                                                                                newDetalles[tIdx].footer[fIdx].es = evt.target.value
                                                                                                setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                            }}
                                                                                        />
                                                                                        <br />
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {
                                                                            newAtencionTabla.tabla[tIdx].footer && 
                                                                            newAtencionTabla.tabla[tIdx].footer.map((foot, fIdx) => {
                                                                                return (
                                                                                    <>
                                                                                        <Form.Label className="label-admin" htmlFor="txtCodigo">Footer (EN)</Form.Label>
                                                                                        <Form.Control 
                                                                                            className={`form-field-admin`}  
                                                                                            type="text" 
                                                                                            id="txtCodigo"
                                                                                            placeholder=""
                                                                                            value={newAtencionTabla.tabla[tIdx].footer[fIdx].en}
                                                                                            onChange={(evt) => {
                    
                                                                                                let newDetalles = newAtencionTabla.tabla;
                                                                                                newDetalles[tIdx].footer[fIdx].en = evt.target.value
                                                                                                setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                                            }}
                                                                                        />
                                                                                        <br />
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Col>
                                                                    <Col lg={1}>
                                                                        <Form.Label className="label-admin" htmlFor="txtCodigo">Listas</Form.Label>
                                                                        <Form.Select 
                                                                            className={`form-field-admin`} 
                                                                            value={newAtencionTabla.tabla[tIdx].footer.length}
                                                                            onChange={(evt) => {
                                                                                let newDetalles = newAtencionTabla.tabla;
                                                                                let newFooter = [];

                                                                                for (let i = 0; i < parseInt(evt.target.value); i++){
                                                                                    newFooter.push({ es: "", en: "" })
                                                                                }

                                                                                newDetalles[tIdx].footer = newFooter;
                                                                                setNewAtencionTabla({ ...newAtencionTabla, tabla: newDetalles });
                                                                            }}
                                                                        >   
                                                                            <option>1</option>
                                                                            <option>2</option>
                                                                            <option>3</option>
                                                                            <option>4</option>
                                                                            <option>5</option>
                                                                            <option>6</option>
                                                                            <option>7</option>
                                                                            <option>8</option>
                                                                            <option>9</option>
                                                                            <option>10</option>
                                                                        </Form.Select>
                                                                    </Col>
                                                                    <Col lg={2}>
                                                                        <br />
                                                                        <Button className="btn-admin-eliminar" onClick={() => {
                                                                            let dataTabla = newAtencionTabla.tabla;
                                                                            dataTabla.splice(tIdx, 1);
                                                                            setNewAtencionTabla({ ...newAtencionTabla, tabla: dataTabla });
                                                                        }}>Quitar</Button>
                                                                    </Col>
                                                                    <hr />
                                                                </Row>
                                                            )
                                                        }
                                                    })
                                                }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={2}>
                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Tamaño de tabla</Form.Label>
                                                    <Form.Select 
                                                        className={`form-field-admin`} 
                                                        value={newAtencionTabla.col}
                                                        onChange={(evt) => {
                                                            setNewAtencionTabla({ ...newAtencionTabla, col: parseInt(evt.target.value) });
                                                        }}
                                                    >   
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                        <option>7</option>
                                                        <option>8</option>
                                                        <option>9</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                        <option>12</option>
                                                    </Form.Select>
                                                </Col>
                                                <Col lg={8}>
                                                </Col>
                                                <Col lg={2}>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let newAtt = ruta.atencion;
                                                        newAtt.push(newAtencionTabla);
                                                        setRuta({ ...ruta, atencion: newAtt });
                                                    }}>Agregar Tabla</Button>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="masInfo" title="Contactos">
                                            <Row>
                                                <Col lg={2}>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = ruta.atencion;
                                                        detalles.push({ masInfo: { col: 12 } });
                                                        setNewAtencionTabla({ ...newAtencionTabla, tabla: detalles })
                                                    }}>Agregar Columna 100%</Button>
                                                </Col>
                                                <Col lg={2}>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = ruta.atencion;
                                                        detalles.push({ masInfo: { col: 6 } });
                                                        setNewAtencionTabla({ ...newAtencionTabla, tabla: detalles })
                                                    }}>Agregar Columna 50%</Button>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="lista" title="Info">
                                            <Row>
                                                <Col lg={2}>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = newAtencionLista.lista;
                                                        detalles.textos.push({ titulo: { es: "", en: "" } });
                                                        setNewAtencionLista({ ...newAtencionLista, lista: detalles });
                                                    }}>Agregar Titulo</Button>
                                                </Col>
                                                <Col lg={2}>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = newAtencionLista.lista;
                                                        detalles.textos.push({ texto: { es: "", en: "" } });
                                                        setNewAtencionLista({ ...newAtencionLista, lista: detalles });
                                                    }}>Agregar Texto</Button>
                                                </Col>
                                                <Col lg={2}>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = newAtencionLista.lista;
                                                        detalles.textos.push({ listas: [{ es: "", en: "" }] });
                                                        setNewAtencionLista({ ...newAtencionLista, lista: detalles });
                                                    }}>Agregar Listado</Button>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col lg={12}>
                                                    {
                                                        newAtencionLista.lista.textos &&
                                                        newAtencionLista.lista.textos.map((lt, ltIdx) => {
                                                            if (lt.titulo) {
                                                                return (
                                                                <Row>
                                                                    <Col lg={5}>
                                                                        <Form.Label className="label-admin" htmlFor="txtCodigo">Título (ES)</Form.Label>
                                                                        <Form.Control 
                                                                            className={`form-field-admin`}  
                                                                            type="text" 
                                                                            id="txtCodigo"
                                                                            placeholder=""
                                                                            value={lt.titulo.es}
                                                                            onChange={(evt) => {

                                                                                let dataLista = newAtencionLista.lista.textos;
                                                                                dataLista[ltIdx].titulo.es = evt.target.value
                                                                                setNewAtencionLista({ ...newAtencionLista, lista: { textos: dataLista } });
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col lg={5}>
                                                                        <Form.Label className="label-admin" htmlFor="txtCodigo">Título (EN)</Form.Label>
                                                                        <Form.Control 
                                                                            className={`form-field-admin`}  
                                                                            type="text" 
                                                                            id="txtCodigo"
                                                                            placeholder=""
                                                                            value={lt.titulo.en}
                                                                            onChange={(evt) => {

                                                                                let dataLista = newAtencionLista.lista.textos;
                                                                                dataLista[ltIdx].titulo.en = evt.target.value
                                                                                setNewAtencionLista({ ...newAtencionLista, lista: { textos: dataLista } });
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col lg={2}>
                                                                        <br />
                                                                        <Button className="btn-admin-eliminar" onClick={() => {
                                                                            let dataLista = newAtencionLista.lista.textos;
                                                                            dataLista.splice(ltIdx, 1);
                                                                            setNewAtencionLista({ ...newAtencionLista, lista: { textos: dataLista } });
                                                                        }}>Quitar</Button>
                                                                    </Col>
                                                                    <hr />
                                                                </Row>
                                                                )
                                                            }
                                                            else if (lt.texto) {
                                                                return (
                                                                    <Row>
                                                                        <Col lg={5}>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Texto (ES)</Form.Label>
                                                                            <Form.Control 
                                                                                className={`form-field-admin`}  
                                                                                type="text" 
                                                                                id="txtCodigo"
                                                                                placeholder=""
                                                                                value={lt.texto.es}
                                                                                onChange={(evt) => {
    
                                                                                    let dataLista = newAtencionLista.lista.textos;
                                                                                    dataLista[ltIdx].texto.es = evt.target.value
                                                                                    setNewAtencionLista({ ...newAtencionLista, lista: { textos: dataLista } });
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col lg={5}>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Texto (EN)</Form.Label>
                                                                            <Form.Control 
                                                                                className={`form-field-admin`}  
                                                                                type="text" 
                                                                                id="txtCodigo"
                                                                                placeholder=""
                                                                                value={lt.texto.en}
                                                                                onChange={(evt) => {
    
                                                                                    let dataLista = newAtencionLista.lista.textos;
                                                                                    dataLista[ltIdx].texto.en = evt.target.value
                                                                                    setNewAtencionLista({ ...newAtencionLista, lista: { textos: dataLista } });
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col lg={2}>
                                                                            <br />
                                                                            <Button className="btn-admin-eliminar" onClick={() => {
                                                                                let dataLista = newAtencionLista.lista.textos;
                                                                                dataLista.splice(ltIdx, 1);
                                                                                setNewAtencionLista({ ...newAtencionLista, lista: { textos: dataLista } });
                                                                            }}>Quitar</Button>
                                                                        </Col>
                                                                        <hr />
                                                                    </Row>
                                                                    )
                                                            }
                                                            else if (lt.listas) {
                                                                return (
                                                                    <>
                                                                        <Row>
                                                                            <Col lg={5}>
                                                                                <Form.Label className="label-admin" htmlFor="txtCodigo">Listado (ES)
                                                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                                                        let dataLista = newAtencionLista.lista.textos;
                                                                                        dataLista[ltIdx].listas.push({ es: "", en: "" })
                                                                                        setNewAtencionLista({ ...newAtencionLista, lista: { textos: dataLista } });
                                                                                    }}> + </Button>
                                                                                </Form.Label>
                                                                                {
                                                                                    lt.listas.length > 0 && 
                                                                                    lt.listas.map((bdy, bdyIdx) => {
                                                                                        return (
                                                                                            <Form.Control 
                                                                                                className={`form-field-admin`}  
                                                                                                type="text" 
                                                                                                id="txtCodigo"
                                                                                                placeholder=""
                                                                                                value={bdy.es}
                                                                                                onChange={(evt) => {
                
                                                                                                    let dataLista = newAtencionLista.lista.textos;
                                                                                                    dataLista[ltIdx].listas[bdyIdx].es = evt.target.value
                                                                                                    setNewAtencionLista({ ...newAtencionLista, lista: { textos: dataLista } });
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <br />
                                                                            </Col>
                                                                            <Col lg={5}>
                                                                                <Form.Label className="label-admin" htmlFor="txtCodigo">Listado (EN)
                                                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                                                        let dataLista = newAtencionLista.lista.textos;
                                                                                        dataLista[ltIdx].listas.push({ es: "", en: "" })
                                                                                        setNewAtencionLista({ ...newAtencionLista, lista: { textos: dataLista } });
                                                                                    }}> + </Button>
                                                                                </Form.Label>
                                                                                {
                                                                                    lt.listas.length > 0 && 
                                                                                    lt.listas.map((bdy, bdyIdx) => {
                                                                                        return (
                                                                                            <Form.Control 
                                                                                                className={`form-field-admin`}  
                                                                                                type="text" 
                                                                                                id="txtCodigo"
                                                                                                placeholder=""
                                                                                                value={bdy.en}
                                                                                                onChange={(evt) => {
                
                                                                                                    let dataLista = newAtencionLista.lista.textos;
                                                                                                    dataLista[ltIdx].listas[bdyIdx].en = evt.target.value
                                                                                                    setNewAtencionLista({ ...newAtencionLista, lista: { textos: dataLista } });
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <br />
                                                                            </Col>
                                                                            <Col lg={2}>
                                                                                <br />
                                                                                <Button className="btn-admin-eliminar" onClick={() => {
                                                                                    let dataLista = newAtencionLista.lista.textos;
                                                                                    dataLista.splice(ltIdx, 1);
                                                                                    setNewAtencionLista({ ...newAtencionLista, lista: { textos: dataLista } });
                                                                                }}>Quitar</Button>
                                                                            </Col>
                                                                            <hr />
                                                                        </Row>
                                                                    </>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    {
                                                        newAtencionLista.lista.textos && newAtencionLista.lista.textos.length > 0 &&
                                                        <Row>
                                                            <Col lg={10}>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                                    let detalles = ruta.atencion;
                                                                    detalles.push(newAtencionLista);
                                                                    setRuta({ ...ruta, atencion: detalles })
                                                                }}>Agregar</Button>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="listaImagen" title="Info + Imágenes">
                                            <Row>
                                                <Col lg={2}>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = newAtencionListaImagen.listaImagen;
                                                        detalles.textos.push({ titulo: { es: "", en: "" } });
                                                        setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: detalles });
                                                    }}>Agregar Titulo</Button>
                                                </Col>
                                                <Col lg={2}>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = newAtencionListaImagen.listaImagen;
                                                        detalles.textos.push({ texto: { es: "", en: "" } });
                                                        setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: detalles });
                                                    }}>Agregar Texto</Button>
                                                </Col>
                                                <Col lg={2}>
                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                        let detalles = newAtencionListaImagen.listaImagen;
                                                        detalles.textos.push({ listas: [{ es: "", en: "" }] });
                                                        setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: detalles });
                                                    }}>Agregar Listado</Button>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col lg={12}>
                                                    {
                                                        newAtencionListaImagen.listaImagen.textos &&
                                                        newAtencionListaImagen.listaImagen.textos.map((lt, ltIdx) => {
                                                            if (lt.titulo) {
                                                                return (
                                                                <Row>
                                                                    <Col lg={5}>
                                                                        <Form.Label className="label-admin" htmlFor="txtCodigo">Título (ES)</Form.Label>
                                                                        <Form.Control 
                                                                            className={`form-field-admin`}  
                                                                            type="text" 
                                                                            id="txtCodigo"
                                                                            placeholder=""
                                                                            value={lt.titulo.es}
                                                                            onChange={(evt) => {

                                                                                let dataLista = newAtencionListaImagen.listaImagen.textos;
                                                                                dataLista[ltIdx].titulo.es = evt.target.value
                                                                                setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: { ...newAtencionListaImagen.listaImagen, textos: dataLista } });
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col lg={5}>
                                                                        <Form.Label className="label-admin" htmlFor="txtCodigo">Título (EN)</Form.Label>
                                                                        <Form.Control 
                                                                            className={`form-field-admin`}  
                                                                            type="text" 
                                                                            id="txtCodigo"
                                                                            placeholder=""
                                                                            value={lt.titulo.en}
                                                                            onChange={(evt) => {

                                                                                let dataLista = newAtencionListaImagen.listaImagen.textos;
                                                                                dataLista[ltIdx].titulo.en = evt.target.value
                                                                                setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: { ...newAtencionListaImagen.listaImagen, textos: dataLista } });
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col lg={2}>
                                                                        <br />
                                                                        <Button className="btn-admin-eliminar" onClick={() => {
                                                                            let dataLista = newAtencionListaImagen.listaImagen.textos;
                                                                            dataLista.splice(ltIdx, 1);
                                                                            setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: { ...newAtencionListaImagen.listaImagen, textos: dataLista } });
                                                                        }}>Quitar</Button>
                                                                    </Col>
                                                                    <hr />
                                                                </Row>
                                                                )
                                                            }
                                                            else if (lt.texto) {
                                                                return (
                                                                    <Row>
                                                                        <Col lg={5}>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Texto (ES)</Form.Label>
                                                                            <Form.Control 
                                                                                className={`form-field-admin`}  
                                                                                type="text" 
                                                                                id="txtCodigo"
                                                                                placeholder=""
                                                                                value={lt.texto.es}
                                                                                onChange={(evt) => {
    
                                                                                    let dataLista = newAtencionListaImagen.listaImagen.textos;
                                                                                    dataLista[ltIdx].texto.es = evt.target.value
                                                                                    setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: { ...newAtencionListaImagen.listaImagen, textos: dataLista } });
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col lg={5}>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Texto (EN)</Form.Label>
                                                                            <Form.Control 
                                                                                className={`form-field-admin`}  
                                                                                type="text" 
                                                                                id="txtCodigo"
                                                                                placeholder=""
                                                                                value={lt.texto.en}
                                                                                onChange={(evt) => {
    
                                                                                    let dataLista = newAtencionListaImagen.listaImagen.textos;
                                                                                    dataLista[ltIdx].texto.en = evt.target.value
                                                                                    setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: { ...newAtencionListaImagen.listaImagen, textos: dataLista } });
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col lg={2}>
                                                                            <br />
                                                                            <Button className="btn-admin-eliminar" onClick={() => {
                                                                                let dataLista = newAtencionListaImagen.listaImagen.textos;
                                                                                dataLista.splice(ltIdx, 1);
                                                                                setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: { ...newAtencionListaImagen.listaImagen, textos: dataLista } });
                                                                            }}>Quitar</Button>
                                                                        </Col>
                                                                        <hr />
                                                                    </Row>
                                                                    )
                                                            }
                                                            else if (lt.listas) {
                                                                return (
                                                                    <>
                                                                        <Row>
                                                                            <Col lg={5}>
                                                                                <Form.Label className="label-admin" htmlFor="txtCodigo">Listado (ES)
                                                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                                                        let dataLista = newAtencionListaImagen.listaImagen.textos;
                                                                                        dataLista[ltIdx].listas.push({ es: "", en: "" })
                                                                                        setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: { ...newAtencionListaImagen.listaImagen, textos: dataLista } });
                                                                                    }}> + </Button>
                                                                                </Form.Label>
                                                                                {
                                                                                    lt.listas.length > 0 && 
                                                                                    lt.listas.map((bdy, bdyIdx) => {
                                                                                        return (
                                                                                            <Form.Control 
                                                                                                className={`form-field-admin`}  
                                                                                                type="text" 
                                                                                                id="txtCodigo"
                                                                                                placeholder=""
                                                                                                value={bdy.es}
                                                                                                onChange={(evt) => {
                
                                                                                                    let dataLista = newAtencionListaImagen.listaImagen.textos;
                                                                                                    dataLista[ltIdx].listas[bdyIdx].es = evt.target.value
                                                                                                    setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: { ...newAtencionListaImagen.listaImagen, textos: dataLista } });
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <br />
                                                                            </Col>
                                                                            <Col lg={5}>
                                                                                <Form.Label className="label-admin" htmlFor="txtCodigo">Listado (EN)
                                                                                    <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                                                        let dataLista = newAtencionListaImagen.listaImagen.textos;
                                                                                        dataLista[ltIdx].listas.push({ es: "", en: "" })
                                                                                        setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: { ...newAtencionListaImagen.listaImagen, textos: dataLista } });
                                                                                    }}> + </Button>
                                                                                </Form.Label>
                                                                                {
                                                                                    lt.listas.length > 0 && 
                                                                                    lt.listas.map((bdy, bdyIdx) => {
                                                                                        return (
                                                                                            <Form.Control 
                                                                                                className={`form-field-admin`}  
                                                                                                type="text" 
                                                                                                id="txtCodigo"
                                                                                                placeholder=""
                                                                                                value={bdy.en}
                                                                                                onChange={(evt) => {
                
                                                                                                    let dataLista = newAtencionListaImagen.listaImagen.textos;
                                                                                                    dataLista[ltIdx].listas[bdyIdx].en = evt.target.value
                                                                                                    setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: { ...newAtencionListaImagen.listaImagen, textos: dataLista } });
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <br />
                                                                            </Col>
                                                                            <Col lg={2}>
                                                                                <br />
                                                                                <Button className="btn-admin-eliminar" onClick={() => {
                                                                                    let dataLista = newAtencionListaImagen.listaImagen.textos;
                                                                                    dataLista.splice(ltIdx, 1);
                                                                                    setNewAtencionListaImagen({ ...newAtencionListaImagen, listaImagen: { ...newAtencionListaImagen.listaImagen, textos: dataLista } });
                                                                                }}>Quitar</Button>
                                                                            </Col>
                                                                            <hr />
                                                                        </Row>
                                                                    </>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    <Row className="justify-content-center">
                                                        <Col lg={6}>
                                                            <Row>
                                                                <Col>
                                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen superior</Form.Label>
                                                                    <Form.Control 
                                                                        className={`form-field-admin`} 
                                                                        type="file" 
                                                                        id="txtFileL"
                                                                        placeholder="Sube la imagen background."
                                                                        accept="image/jpeg"
                                                                        onChange={(evt) => {
                                                                            if (evt.target.files.length > 0) {

                                                                                setShowLoading(true);

                                                                                let formData = new FormData();

                                                                                formData.append("pdf", evt.target.files[0]);
                                                                                
                                                                                fetch('https://recursos.tabsa.cl/api/file/save', {
                                                                                    method: "POST",
                                                                                    body: formData
                                                                                })
                                                                                .then(response => {
                                                                                    if (response.ok) {
                                                                                        response.json().then(data => {
                                                                                            setNewAtencionListaImagen({ listaImagen: { ...newAtencionListaImagen.listaImagen, imgT: data.url } });
                                                                                            setShowLoading(false);
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                                        setShowLoading(false);
                                                                                    }
                                                                                })
                                                                                .catch(error => {
                                                                                    console.log(error)
                                                                                })

                                                                            }
                                                                        }}
                                                                    />
                                                                    <p className="label-admin-recomendacion">* Resolución recomendada: 726x521</p> 
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen inferior izquierda</Form.Label>
                                                                    <Form.Control 
                                                                        className={`form-field-admin`} 
                                                                        type="file" 
                                                                        id="txtFileL"
                                                                        placeholder="Sube la imagen background."
                                                                        accept="image/jpeg"
                                                                        onChange={(evt) => {
                                                                            if (evt.target.files.length > 0) {

                                                                                setShowLoading(true);

                                                                                let formData = new FormData();

                                                                                formData.append("pdf", evt.target.files[0]);
                                                                                
                                                                                fetch('https://recursos.tabsa.cl/api/file/save', {
                                                                                    method: "POST",
                                                                                    body: formData
                                                                                })
                                                                                .then(response => {
                                                                                    if (response.ok) {
                                                                                        response.json().then(data => {
                                                                                            setNewAtencionListaImagen({ listaImagen: { ...newAtencionListaImagen.listaImagen, imgBL: data.url } });
                                                                                            setShowLoading(false);
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                                        setShowLoading(false);
                                                                                    }
                                                                                })
                                                                                .catch(error => {
                                                                                    console.log(error)
                                                                                })

                                                                            }
                                                                        }}
                                                                    />
                                                                    <p className="label-admin-recomendacion">* Resolución recomendada: 471x399</p> 
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Row>
                                                                        <Col>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen centro derecha</Form.Label>
                                                                            <Form.Control 
                                                                                className={`form-field-admin`} 
                                                                                type="file" 
                                                                                id="txtFileL"
                                                                                placeholder="Sube la imagen background."
                                                                                accept="image/jpeg"
                                                                                onChange={(evt) => {
                                                                                    if (evt.target.files.length > 0) {

                                                                                        setShowLoading(true);

                                                                                        let formData = new FormData();

                                                                                        formData.append("pdf", evt.target.files[0]);
                                                                                        
                                                                                        fetch('https://recursos.tabsa.cl/api/file/save', {
                                                                                            method: "POST",
                                                                                            body: formData
                                                                                        })
                                                                                        .then(response => {
                                                                                            if (response.ok) {
                                                                                                response.json().then(data => {
                                                                                                    setNewAtencionListaImagen({ listaImagen: { ...newAtencionListaImagen.listaImagen, imgCR: data.url } });
                                                                                                    setShowLoading(false);
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                                                setShowLoading(false);
                                                                                            }
                                                                                        })
                                                                                        .catch(error => {
                                                                                            console.log(error)
                                                                                        })

                                                                                    }
                                                                                }}
                                                                            />
                                                                            <p className="label-admin-recomendacion">* Resolución recomendada: 242x232</p> 
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen inferior derecha</Form.Label>
                                                                            <Form.Control 
                                                                                className={`form-field-admin`} 
                                                                                type="file" 
                                                                                id="txtFileL"
                                                                                placeholder="Sube la imagen background."
                                                                                accept="image/jpeg"
                                                                                onChange={(evt) => {
                                                                                    if (evt.target.files.length > 0) {

                                                                                        setShowLoading(true);

                                                                                        let formData = new FormData();

                                                                                        formData.append("pdf", evt.target.files[0]);
                                                                                        
                                                                                        fetch('https://recursos.tabsa.cl/api/file/save', {
                                                                                            method: "POST",
                                                                                            body: formData
                                                                                        })
                                                                                        .then(response => {
                                                                                            if (response.ok) {
                                                                                                response.json().then(data => {
                                                                                                    setNewAtencionListaImagen({ listaImagen: { ...newAtencionListaImagen.listaImagen, imgBR: data.url } });
                                                                                                    setShowLoading(false);
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                                                setShowLoading(false);
                                                                                            }
                                                                                        })
                                                                                        .catch(error => {
                                                                                            console.log(error)
                                                                                        })

                                                                                    }
                                                                                }}
                                                                            />
                                                                            <p className="label-admin-recomendacion">* Resolución recomendada: 242x154</p> 
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <Row className="no-margin">
                                                                <Col className="no-margin" lg={12}>
                                                                {
                                                                    newAtencionListaImagen.listaImagen && newAtencionListaImagen.listaImagen.imgT &&
                                                                    <img src={newAtencionListaImagen.listaImagen.imgT} className="ferry-img-t" alt="ferry" />
                                                                }
                                                                </Col>
                                                            </Row>
                                                            <Row className="no-margin">
                                                                <Col className="no-margin" lg={8}>
                                                                {
                                                                    newAtencionListaImagen.listaImagen && newAtencionListaImagen.listaImagen.imgBL &&
                                                                    <img src={newAtencionListaImagen.listaImagen.imgBL} className="ferry-img-b-l" alt="ferry" />
                                                                }
                                                                </Col>
                                                                <Col className="no-margin" lg={4}>
                                                                    <Row className="no-margin">
                                                                        <Col className="no-margin" lg={12}>
                                                                        {
                                                                            newAtencionListaImagen.listaImagen && newAtencionListaImagen.listaImagen.imgCR &&
                                                                            <img src={newAtencionListaImagen.listaImagen.imgCR} className="ferry-img-c-r" alt="ferry" />
                                                                        }
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="no-margin">
                                                                        <Col className="no-margin" lg={12}>
                                                                        {
                                                                            newAtencionListaImagen.listaImagen && newAtencionListaImagen.listaImagen.imgBR &&
                                                                            <img src={newAtencionListaImagen.listaImagen.imgBR} className="ferry-img-b-r" alt="ferry" />
                                                                        }
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        newAtencionListaImagen.listaImagen.textos && newAtencionListaImagen.listaImagen.textos.length > 0 &&
                                                        <Row>
                                                            <hr />
                                                            <br />
                                                            <Col lg={10}>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <Button className="btn-admin-editar btn btn-primary" onClick={() => {
                                                                    let detalles = ruta.atencion;
                                                                    detalles.push(newAtencionListaImagen);
                                                                    setRuta({ ...ruta, atencion: detalles })
                                                                }}>Agregar</Button>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={11}>
                                    <Row className="justify-content-center">
                                        {
                                            ruta.atencion && ruta.atencion.length > 0 &&
                                            ruta.atencion.map((s, idx) => {
                                                if (s.tabla) {
                                                    return (
                                                        <Col lg={s.col} className="cmsi-padd-col-atencion cmsi-padd-col-atencion-delete" onClick={() => { 
                                                            let newAtencion = ruta.atencion;
                                                            newAtencion.splice(idx, 1);
                                                            setRuta({ ...ruta, atencion: newAtencion })
                                                        }}>
                                                            {
                                                                s.tabla.map((tbl, tblIdx) => {
                                                                    if (tbl.titulo) {
                                                                        return (
                                                                            <table className="cmsi-atencion-table">
                                                                                <thead key={`tbl-${idx}-head-titulo-${tblIdx}`}>
                                                                                    <tr className="cmsi-atencion-table-title">
                                                                                        <td colSpan={tbl.span}>
                                                                                            {tbl.titulo.es}
                                                                                        </td>
                                                                                    </tr>
                                                                                </thead>
                                                                            </table>
                                                                        )
                                                                    }
                                                                    else if (tbl.subtitulos) {
                                                                        return (
                                                                            <table className="cmsi-atencion-table">
                                                                                <thead key={`tbl-${idx}-head-subtitulo-${tblIdx}`}>
                                                                                    <tr className="cmsi-atencion-table-subtitle">
                                                                                        {tbl.subtitulos.map((sub, subIdx) => (<td key={`tbl-${idx}-head-subtitulo-${tblIdx}-td-${subIdx}`} colSpan={tbl.span}>{sub.es}</td>))}
                                                                                    </tr>
                                                                                </thead>
                                                                            </table>
                                                                        )
                                                                    }
                                                                    else if (tbl.body) {
                                                                        return (
                                                                            <table className="cmsi-atencion-table">
                                                                                <tbody key={`tbl-${idx}-body-${tblIdx}`}>
                                                                                    <tr>
                                                                                        {
                                                                                            tbl.body.map((bd, bdIdx) => {
                                                                                                return (
                                                                                                    <td key={`tbl-${idx}-body-${tblIdx}-td-${bdIdx}`} width={`${(100 / tbl.body.length)}%`}>
                                                                                                        {
                                                                                                            bd.map((txt, txtIdx) => {
                                                                                                                if (txtIdx === 0) {
                                                                                                                    return (txt.es)
                                                                                                                }
                                                                                                                else {
                                                                                                                    return (<>
                                                                                                                        <br />
                                                                                                                        {txt.es}
                                                                                                                    </>)
                                                                                                                }
                                                                                                            })
                                                                                                        }
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        )
                                                                    }
                                                                    else if (tbl.footer) {
                                                                        return (
                                                                            <table className="cmsi-atencion-table">
                                                                                <tfoot key={`tbl-${idx}-body-${tblIdx}`}>
                                                                                    <tr>
                                                                                        <td key={`tbl-${idx}-footer-${tblIdx}-td`} colSpan={tbl.span}>
                                                                                            <br />
                                                                                            <ul>
                                                                                            {
                                                                                                tbl.footer.map((ft, ftIdx) => {
                                                                                                    return (<li key={`tbl-${idx}-footer-${tblIdx}-li-${ftIdx}`}>{ft.es}</li>)
                                                                                                })
                                                                                            }
                                                                                            </ul>
                                                                                            <br />
                                                                                        </td>
                                                                                    </tr>
                                                                                </tfoot>
                                                                            </table>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </Col>
                                                    )
                                                }
                                                else if (s.masInfo) {
                                                    return (
                                                        <Col lg={s.masInfo.col} sm={12} xs={12} className="cmsi-padd-col-atencion cmsi-padd-col-atencion-delete" onClick={() => { 
                                                            let newAtencion = ruta.atencion;
                                                            newAtencion.splice(idx, 1);
                                                            setRuta({ ...ruta, atencion: newAtencion })
                                                        }}>
                                                            {
                                                                s.masInfo.col === 6 &&
                                                                <>
                                                                    <Row className="justify-content-center">
                                                                        <Col lg={8} sm={4} xs={4}>
                                                                            <div className="cmsi-atencion-div-info">
                                                                                <Row className="justify-content-center">
                                                                                    <Col lg={12}>
                                                                                        <p className="cmsi-atencion-p-info cmsi-atencion-p-info-centered">
                                                                                            {t("nuestros-servicios.parenas-pwilliams.atencion.mas-info")}
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="justify-content-center">
                                                                                    <Col lg={12}>
                                                                                        <p className="cmsi-atencion-p-info cmsi-atencion-p-info-centered"><img className="cmsi-phone" src={CelularSVG} alt="phone" /> 612 728100</p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="justify-content-center">
                                                                        <Col lg={12} sm={5} xs={5}>
                                                                            <img src={CLSVG} alt="tabsa.cl" className="cmsi-atencion-cl-image" />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="justify-content-center">
                                                                        <Col lg={12} sm={5} xs={5}>
                                                                            <img src={FacebookSVG} className="cmsi-atencion-link-image" alt="facebook" onClick={() => { window.location.href = "https://web.facebook.com/TABSA"}} />
                                                                            <img src={TwitterSVG} className="cmsi-atencion-link-image" alt="x" onClick={() => { window.location.href = "https://twitter.com/_tabsa"}} />
                                                                            <img src={InstagramSVG} className="cmsi-atencion-link-image" alt="instagram" onClick={() => { window.location.href = "https://www.instagram.com/_tabsa/"}} />
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            }
                                                            {
                                                                s.masInfo.col === 12 &&
                                                                <Row className="justify-content-center">
                                                                    <Col lg={6} sm={6} xs={6}>
                                                                        <Row className="justify-content-center">
                                                                            <Col lg={2}>
                                                                            </Col>
                                                                            <Col lg={8} sm={4} xs={4}>
                                                                                <div className="cmsi-atencion-div-info">
                                                                                    <Row className="justify-content-center">
                                                                                        <Col lg={12}>
                                                                                            <p className="cmsi-atencion-p-info cmsi-atencion-p-info-centered">
                                                                                                {t("nuestros-servicios.parenas-pwilliams.atencion.mas-info")}
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row className="justify-content-center cmsi-atencion-p-info-centered">
                                                                                        <Col lg={12}>
                                                                                            <p className="cmsi-atencion-p-info"><img className="cmsi-phone" src={CelularSVG} alt="phone" /> 612 728100</p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={6} sm={6} xs={6}>
                                                                        <Row className="justify-content-center">
                                                                            <Col lg={12} sm={5} xs={5}>
                                                                                <img src={CLSVG} alt="tabsa.cl" className="cmsi-atencion-cl-image" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="justify-content-center">
                                                                            <Col lg={12} sm={5} xs={5}>
                                                                                <img src={FacebookSVG} className="cmsi-atencion-link-image" alt="facebook" onClick={() => { window.location.href = "https://web.facebook.com/TABSA"}} />
                                                                                <img src={TwitterSVG} className="cmsi-atencion-link-image" alt="x" onClick={() => { window.location.href = "https://twitter.com/_tabsa"}} />
                                                                                <img src={InstagramSVG} className="cmsi-atencion-link-image" alt="instagram" onClick={() => { window.location.href = "https://www.instagram.com/_tabsa/"}} />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </Col>
                                                    )
                                                }
                                                else if (s.listaImagen) {
                                                    return (
                                                        <Col lg={12} className="cmsi-padd-col-atencion cmsi-padd-col-atencion-delete" onClick={() => { 
                                                            let newAtencion = ruta.atencion;
                                                            newAtencion.splice(idx, 1);
                                                            setRuta({ ...ruta, atencion: newAtencion })
                                                        }}>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    {
                                                                        s.listaImagen.textos && s.listaImagen.textos.length > 0 &&
                                                                        s.listaImagen.textos.map((textos) => {
                                                                            if (textos.titulo) {
                                                                                return (<p className="p-sub-cmsi-atencion-s">{textos.titulo.es}</p>)
                                                                            }
                                                                            else if (textos.texto) {
                                                                                return (<p className="p-cmsi-atencion-s cmsi-atencion-justify">{textos.texto.es}</p>)
                                                                            }
                                                                            else if (textos.listas) {
                                                                                return (<ul>
                                                                                    {
                                                                                        textos.listas.map((lista) => (<li className="p-cmsi-atencion-s cmsi-atencion-justify">{lista.es}</li>))
                                                                                    }
                                                                                </ul>)
                                                                            }
                                                                        })
                                                                    }
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Row className="no-margin">
                                                                        <Col className="no-margin" lg={12}>
                                                                            {
                                                                                s.listaImagen.imgT &&
                                                                                <img src={s.listaImagen.imgT} className="ferry-img-t" alt="ferry" />
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="no-margin">
                                                                        <Col className="no-margin" lg={8}>
                                                                            {
                                                                                s.listaImagen.imgBL &&
                                                                                <img src={s.listaImagen.imgBL} className="ferry-img-b-l" alt="ferry" />
                                                                            }
                                                                        </Col>
                                                                        <Col className="no-margin" lg={4}>
                                                                            <Row className="no-margin">
                                                                                <Col className="no-margin" lg={12}>
                                                                                    {
                                                                                        s.listaImagen.imgCR &&
                                                                                        <img src={s.listaImagen.imgCR} className="ferry-img-c-r" alt="ferry" />
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="no-margin">
                                                                                <Col className="no-margin" lg={12}>
                                                                                    {
                                                                                        s.listaImagen.imgBR &&
                                                                                        <img src={s.listaImagen.imgBR} className="ferry-img-b-r" alt="ferry" />
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                }
                                                else if (s.lista) {
                                                    return (
                                                        <Col lg={12} className="cmsi-padd-col-atencion cmsi-padd-col-atencion-delete" onClick={() => { 
                                                            let newAtencion = ruta.atencion;
                                                            newAtencion.splice(idx, 1);
                                                            setRuta({ ...ruta, atencion: newAtencion })
                                                        }}>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    {
                                                                        s.lista.textos && s.lista.textos.length > 0 &&
                                                                        s.lista.textos.map((textos) => {
                                                                            if (textos.titulo) {
                                                                                return (<p className="p-sub-cmsi-atencion-s">{textos.titulo.es}</p>)
                                                                            }
                                                                            else if (textos.texto) {
                                                                                return (<p className="p-cmsi-atencion-s cmsi-atencion-justify">{textos.texto.es}</p>)
                                                                            }
                                                                            else if (textos.listas) {
                                                                                return (<ul>
                                                                                    {
                                                                                        textos.listas.map((lista) => (<li className="p-cmsi-atencion-s cmsi-atencion-justify">{lista.es}</li>))
                                                                                    }
                                                                                </ul>)
                                                                            }
                                                                        })
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                }
                                            })
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                        </Col>
                    </Row>
                }
            </Container>
        </>
    )
}

export default AdminRutas;