import "./Modals.css";
import { useState, useEffect } from "react";
import CloseButton from "./src/close-circle.svg";

function Modals() {
    const [show, setShow] = useState(true);
    const [modal, setModal] = useState({ img: "", active: false });

    useEffect(() => {
        
        fetch(`${process.env.REACT_APP_API_BASEURL}/Modals/modals`, { method: "GET" }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setModal(data.data);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        }).catch(error => {
            console.log(JSON.stringify(error));
        });
    }, [])

    return (
        <>
            {
                modal.active && show &&
                <div className="div-modal-notf">
                    <a href="https://reservas.tabsa.cl" className="link-modal-notf"><img src={modal.img} alt="mdlntf" className="img-modal-notf" /></a>
                    <img className="cb-modal-notf img-clickable" src={CloseButton} alt="mdlntfcb" onClick={() => setShow(false)} />
                </div>
            }
        </>
    )
}

export default Modals;