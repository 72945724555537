import "./PAPWItinerario.css";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../../components/Navegacion/Navegacion";
import VolverInicio from "../../../components/VolverInicio/VolverInicio";
import Chat from "../../../components/Chat/Chat";
import Footer from "../../../components/Footer/Footer";
import LogoColor from "../src/logo-tabsa-color.png";
import moment from "moment";
import ReactGA from 'react-ga';

import { useTranslation } from "react-i18next";

function PAPWItinerario() {
    const [t, i18n] = useTranslation("global");

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);
    const [ruta, setRuta] = useState({});

    const [idaA, setIdaA] = useState({ data: [] });
    const [idaB, setIdaB] = useState({ data: [] });
    const [idaC, setIdaC] = useState({ data: [] });
    const [idaD, setIdaD] = useState({ data: [] });
    const [idaE, setIdaE] = useState({ data: [] });
    const [idaF, setIdaF] = useState({ data: [] });
    const [vueltaA, setVueltaA] = useState({ data: [] });
    const [vueltaB, setVueltaB] = useState({ data: [] });
    const [vueltaC, setVueltaC] = useState({ data: [] });
    const [vueltaD, setVueltaD] = useState({ data: [] });
    const [vueltaE, setVueltaE] = useState({ data: [] });
    const [vueltaF, setVueltaF] = useState({ data: [] });
    
    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname,
            location: window.location.href,
          });

        fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/ruta/parenas-pwilliams`, { method: "GET" }).then(response => {
          if (response.ok) {
            response.json().then(data => {
              setRuta(data.data);
            })
          }
          else {
            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
          }
        }).catch(error => {
            console.log(JSON.stringify(error));
        });
  
      let firstDate = moment(new Date()).format("DD-MM-yyyy");
      let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");
  
      fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
          if (firstDateResponse.ok) {
              firstDateResponse.json().then(firstDateData => { 
  
                  let newCruces = firstDateData.data.map((d) => ({ 
                      estado: d.estado_detalle.toLowerCase(), 
                      origen: d.origen, 
                      destino: d.destino, 
                      hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                      fecha: d.zarpeOriginal.split(" ")[0]
                  }))
                  setCruces(newCruces);
  
                  fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                      if (secondDateResponse.ok) {
                          secondDateResponse.json().then(secondDateData => { 
                              
                              let newSecondCruces = secondDateData.data.map((d) => ({ 
                                  estado: d.estado_detalle.toLowerCase(), 
                                  origen: d.origen, 
                                  destino: d.destino, 
                                  hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                  fecha: d.zarpeOriginal.split(" ")[0]
                              }))
  
                              let concats = newCruces.concat(newSecondCruces);
                              setCruces(concats);
  
                              let newCrucesSuspendidos = [];
                  
                              concats.map((d) => {
                                  if (d.estado === "suspendido" || d.estado === "cancelado") {
                                      newCrucesSuspendidos.push(d);
                                  }
                              });
  
                              setCrucesSuspendidos(newCrucesSuspendidos);
                              setShowCruce(newCrucesSuspendidos.length > 0)
                          })
                      }
                      else {
                          console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                      }
  
                  })
              })
          }
          else {
              console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
          }
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
  
      fetch(`https://api-cruce.tabsa.cl/api/tramos`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });
  
      let fechaA = new Date();
      fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaA).format("YYYY-MM")}/3`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {

            if (data && data.data) {
                let idas = [];
                let vueltas = [];
    
                data.data.filter((filter) => !filter.pasado).map((item, idx) => {
    
                    if (item.id_tramo === "3") {
                        idas.push(item);
                    }
                    else if (item.id_tramo === "4") {
                        vueltas.push(item);
                    }
    
                    return item;
                })
    
                setIdaA({ fecha: fechaA, data: idas });
                setVueltaA({ fecha: fechaA, data: vueltas });
            }
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });

        let fechaB = new Date();
        fechaB.setDate(1);
        fechaB.setMonth(fechaB.getMonth() + 1);
        fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaB).format("YYYY-MM")}/3`, { method: "GET" }).then(response => {
        if (response.ok) {
            response.json().then(data => {

            if (data && data.data) {
                let idas = [];
                let vueltas = [];
    
                data.data.filter((filter) => !filter.pasado).map((item, idx) => {
    
                    if (item.id_tramo === "3") {
                        idas.push(item);
                    }
                    else if (item.id_tramo === "4") {
                        vueltas.push(item);
                    }
    
                    return item;
                })
    
                setIdaB({ fecha: new Date(fechaB), data: idas });
                setVueltaB({ fecha: new Date(fechaB), data: vueltas });
            }
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });
      
      let fechaC = new Date();
      fechaC.setDate(1);
      fechaC.setMonth(fechaC.getMonth() + 2);   
      fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaC).format("YYYY-MM")}/3`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {

            if (data && data.data) {
                let idas = [];
                let vueltas = [];
    
                data.data.filter((filter) => !filter.pasado).map((item, idx) => {
    
                    if (item.id_tramo === "3") {
                        idas.push(item);
                    }
                    else if (item.id_tramo === "4") {
                        vueltas.push(item);
                    }
    
                    return item;
                })
    
                setIdaC({ fecha: new Date(fechaC), data: idas });
                setVueltaC({ fecha: new Date(fechaC), data: vueltas });
            }
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });

      let fechaD = new Date();
      fechaD.setDate(1);
      fechaD.setMonth(fechaD.getMonth() + 3);

      fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaD).format("YYYY-MM")}/3`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {

            if (data && data.data) {
                let idas = [];
                let vueltas = [];
    
                data.data.filter((filter) => !filter.pasado).map((item, idx) => {
    
                    if (item.id_tramo === "3") {
                        idas.push(item);
                    }
                    else if (item.id_tramo === "4") {
                        vueltas.push(item);
                    }
    
                    return item;
                })
    
                setIdaD({ fecha: new Date(fechaD), data: idas });
                setVueltaD({ fecha: new Date(fechaD), data: vueltas });
            }
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });

      let fechaE = new Date();
      fechaE.setDate(1);
      fechaE.setMonth(fechaE.getMonth() + 4);

      fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaE).format("YYYY-MM")}/3`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {

            if (data && data.data) {
                let idas = [];
                let vueltas = [];
    
                data.data.filter((filter) => !filter.pasado).map((item, idx) => {
    
                    if (item.id_tramo === "3") {
                        idas.push(item);
                    }
                    else if (item.id_tramo === "4") {
                        vueltas.push(item);
                    }
    
                    return item;
                })
    
                setIdaE({ fecha: new Date(fechaE), data: idas });
                setVueltaE({ fecha: new Date(fechaE), data: vueltas });
            }
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });
      
      let fechaF = new Date();
      fechaF.setDate(1);
      fechaF.setMonth(fechaF.getMonth() + 5);

      fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaF).format("YYYY-MM")}/3`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {

            if (data && data.data) {
                let idas = [];
                let vueltas = [];
    
                data.data.filter((filter) => !filter.pasado).map((item, idx) => {
    
                    if (item.id_tramo === "3") {
                        idas.push(item);
                    }
                    else if (item.id_tramo === "4") {
                        vueltas.push(item);
                    }
    
                    return item;
                })
    
                setIdaF({ fecha: new Date(fechaF), data: idas });
                setVueltaF({ fecha: new Date(fechaF), data: vueltas });
            }
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });

    }, []);
  
    const [chatOpen, setChatOpen] = useState(false);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
  
    function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
      setChatOpen(chat);
      setShowSelectLanguage(idiomas);
      setLoginOpen(login);
    }

    return (
        <div className="PAPWItinerario">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`d-none d-lg-block slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
          </div>
          <div className="div-papw-itinerario-s d-none d-lg-block" onClick={() => { showItems(false, false, false, false, false) }}>
            {
              ruta.imgBack &&
              <img className="img-papw-itinerario-s" alt={"papw-itinerario"} src={ruta.imgBack} />
            }
          </div>
          <div className="div-papw-itinerario-body" onClick={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center d-block d-lg-none">
                <Col lg={6} sm={12} xs={12}>
                  <img alt="logo" className="logo-color-xs" src={LogoColor} />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={11} sm={11} xs={11}>
                    <p className="title-papw-itinerario-s">{t("nuestros-servicios.parenas-pwilliams.itinerario.titulo")}</p>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={10} sm={11} xs={11}>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    idaA.data && idaA.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-titulo">
                                                        {t(`months.${idaA.fecha.getMonth()}`).toUpperCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-subtitulo">Punta Arenas - Puerto Williams</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.salida")}
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.llegada")}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                idaA.data && idaA.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    vueltaA.data && vueltaA.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-titulo">
                                                        {t(`months.${vueltaA.fecha.getMonth()}`).toUpperCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-subtitulo">Puerto Williams - Punta Arenas</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.salida")}
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.llegada")}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                vueltaA.data && vueltaA.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    idaB.data && idaB.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                    <thead>
                                        <tr>
                                            <td colSpan={4}>
                                                <div className="row-col-titulo">
                                                    {t(`months.${idaB.fecha.getMonth()}`).toUpperCase()}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                <div className="row-col-subtitulo">Punta Arenas - Puerto Williams</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className="row-col-via">
                                                    {t("itinerarios.salida")}
                                                </div>
                                            </td>
                                            <td colSpan={2}>
                                                <div className="row-col-via">
                                                    {t("itinerarios.llegada")}
                                                </div>
                                            </td>
                                        </tr>
                                    </thead>
                                        <tbody>
                                            {
                                                idaB.data && idaB.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    vueltaB.data && vueltaB.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-titulo">
                                                        {t(`months.${vueltaB.fecha.getMonth()}`).toUpperCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-subtitulo">Puerto Williams - Punta Arenas</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.salida")}
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.llegada")}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                vueltaB.data && vueltaB.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    idaC.data && idaC.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-titulo">
                                                        {t(`months.${idaC.fecha.getMonth()}`).toUpperCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-subtitulo">Punta Arenas - Puerto Williams</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.salida")}
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.llegada")}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                idaC.data && idaC.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    vueltaC.data && vueltaC.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-titulo">
                                                        {t(`months.${vueltaC.fecha.getMonth()}`).toUpperCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-subtitulo">Puerto Williams - Punta Arenas</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.salida")}
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.llegada")}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                vueltaC.data && vueltaC.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    idaD.data && idaD.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-titulo">
                                                        {t(`months.${idaD.fecha.getMonth()}`).toUpperCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-subtitulo">Punta Arenas - Puerto Williams</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.salida")}
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.llegada")}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                idaD.data && idaD.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    vueltaD.data && vueltaD.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-titulo">
                                                        {t(`months.${vueltaD.fecha.getMonth()}`).toUpperCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-subtitulo">Puerto Williams - Punta Arenas</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.salida")}
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.llegada")}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                vueltaD.data && vueltaD.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    idaE.data && idaE.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-titulo">
                                                        {t(`months.${idaE.fecha.getMonth()}`).toUpperCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-subtitulo">Punta Arenas - Puerto Williams</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.salida")}
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.llegada")}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                idaE.data && idaE.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    vueltaE.data && vueltaE.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-titulo">
                                                        {t(`months.${vueltaE.fecha.getMonth()}`).toUpperCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-subtitulo">Puerto Williams - Punta Arenas</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.salida")}
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.llegada")}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                vueltaE.data && vueltaE.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    idaF.data && idaF.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-titulo">
                                                        {t(`months.${idaF.fecha.getMonth()}`).toUpperCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-subtitulo">Punta Arenas - Puerto Williams</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.salida")}
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.llegada")}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                idaF.data && idaF.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="div-papw-itinerario-padd">
                                {
                                    vueltaF.data && vueltaF.data.length > 0 &&
                                    <table className="papw-itinerario-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-titulo">
                                                        {t(`months.${vueltaF.fecha.getMonth()}`).toUpperCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="row-col-subtitulo">Puerto Williams - Punta Arenas</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.salida")}
                                                    </div>
                                                </td>
                                                <td colSpan={2}>
                                                    <div className="row-col-via">
                                                        {t("itinerarios.llegada")}
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                vueltaF.data && vueltaF.data.map((item, idx) => {
                                                    return (
                                                        <tr className={idx === 0 ? "papw-itinerario-table-no-top": ""}>
                                                            <td>{moment(item.zarpeOriginal).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</td>
                                                            <td>{moment(item.arribo).format("DD-MM-YYYY")}</td>
                                                            <td>{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={11}>
                    <ul>
                        <li className="p-papw-itinerario-s">{t("nuestros-servicios.parenas-pwilliams.itinerario.p1")}</li>
                        <li className="p-papw-itinerario-s">{t("nuestros-servicios.parenas-pwilliams.itinerario.p2")}</li>
                        <li className="p-papw-itinerario-s">{t("nuestros-servicios.parenas-pwilliams.itinerario.p3")}</li>
                    </ul>
                </Col>
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/rutas/parenas-pwilliams"} Texto={"volver-inicio.servicios"} />
        </div>
      );
}

export default PAPWItinerario;
